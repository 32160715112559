import React from 'react';
import useFetchProfileData from '../hooks/useFetchProfileData';
import ProfileDetailsView from './ProfileDetailsView';

const ProfileView = () => {
  const { data, loading, error } = useFetchProfileData();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>No profile data available.</div>;
  }

  return (
    <div>
      {/* <h1>Profile</h1>
      <h2>Username: {data.username}</h2> */}
      <ProfileDetailsView data={data} />
      {/* Add more fields as needed */}
      {/* Example: <p>Email: {data.email}</p> */}
    </div>
  );
};

export default ProfileView;
