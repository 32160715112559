import React, { useContext, useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Link } from "react-router-dom";
import { BsGearWideConnected } from "react-icons/bs";
import { firebaseAuth } from "../../provider/AuthProvider";
import TerminalController from "./TerminalController";
import DarkModeToggle from "../aesthetic/DarkModeToggle";
import Terminal from "./Terminal";
// import MyTapesView from "../file_mgmt/MyTapesView";
import Dash from "./dashboard/Dash";
import StandardButton from "../buttons/StandardButton";
import styled, { css } from "styled-components";
import HomeButton from "../buttons/HomeButton";
import NewSubscriptionButton from "../buttons/NewSubscriptionButton";
import MXTFiles from "./dashboard/MXTFiles";
import DND from "./dashboard/DND";

import APIConfig from "../../APIConfig";
// import Merch from "./Merch";
import storage from "../../services/storage";
import { useHistory } from "react-router-dom";
import profile_service from "../../services/profile_service";
import GridContainer from "../themes/GridContainer";
import merch from "../../services/merch";

const Container = styled.div`
    text-align: center;
    align-items: center;
    display: flex;
    .container {
      display: flex; /* or inline-flex */
      flex-basis: auto; /* default auto */
    }
    .draft-list {
      margin: 5px 0;
      padding-left: 40px;
      display: flex;
      flex-direction: column;
    }
    .new-draft-button {
      margin: 5px 0;
      padding-left: 40px;
    }
    ul {
      display: flex;

      /* align-items: stretch; */
      /* Default */
      /* justify-content: space-between; */
      /* width: 100%; */
      /* background: #cacaca; */
      border: 2px solid blue;

      margin: 0;
      padding: 0;
    }
    li {
      /* display: block; */
      /* flex: 0 1 auto;  */
      list-style-type: none;
      display: flex;

      /* background: #fafafa; */
      margin: 10px 0;
    }
    .draft_link {
      border-width: 1px;
      border-color: #91b3ff;
      padding: 5px;
      /* color: #777777; */
    }
    .item {
      order: 5; /* default is 0 */
    }
  `;
const CassetteDraftingView = (props) => {
  const [theme, setTheme] = useState("light");
  const [token, setToken] = useState();
  const [showing, setDraftToggle] = useState(true);
  const [drafts, setDrafts] = useState([]);
  const [merch_drafts, setMerchDrafts] = useState([]);
  const [artistName, setArtistName] = useState("");
  const [_uid, setUID] = useState();
  const [hasStudio, setHasStudio] = useState(false)
  let history = useHistory();

  function loadDrafts() {
    var res = storage.getDrafts();
    var _albums = [];
    res.then(function (result) {

      _albums = result;
      setDrafts(_albums);
    });
    // albums: _albums,
  }

  async function loadMerchDrafts(uid) {
    var _merchItems = [];
    var res = merch.getDrafts(uid);

    res.then(function (result) {

      _merchItems = result;
      setMerchDrafts(_merchItems);
    });
    // albums: _albums,
  }
  async function newAlbum(albumName) {
    var album = await storage.initializeAlbum(albumName);
    history.push({
      pathname: "/create/album",
      album: album,
    });
  }

  async function newMerch() {
    var name = `untitled ${merch_drafts_list.length}`;
    var merchItem = await merch.initialize(name);
    history.push({
      pathname: "/create/merch",
      merchItem: merchItem,
    });
  }

  async function newCassette(albumName) {
    var album = await storage.initializeAlbum(albumName);
    history.push({
      pathname: "/create/cassette",
      album: album,
    });
  }
  // The function that toggles between themes
  const toggleTheme = () => {
    // if the theme is not light, then set it to dark
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const drafts_list = drafts.map((draft) => (
    <li>
      <div className="draft_link">
        <button
          onClick={() => {
            history.push({
              pathname: "/create/cassette",
              album: draft,
            });
          }}
        >
          {draft.name}
        </button>
      </div>
    </li>
  ));

  const merch_drafts_list = merch_drafts.map((draft) => (
    <li>
      <div className="draft_link">
        <button
          onClick={() => {
            history.push({
              pathname: "/create/merch",
              merchItem: draft,
            });
          }}
        >
          {draft.name}
        </button>
      </div>
    </li>
  ));


  const draftsView = () => {
    
      return (
        <div className="draft-list">
          drafts
          {drafts_list}
        </div>
      );
  };


 

  
  function draftingPanel() {
    return (
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "row",
          // padding: 100,
          // display: "inline-block",
          // verticalAlign: "middle",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // display: "inline-block",
            paddingTop: 50,
          }}
        >
          {buttons()}
        </div>
        {/* {toggleDrafts()} */}
        {draftsView()}
      </div>
    );
  }

 
  // album button
  function buttons() {
    return (
      <li>
        <div>{newAlbumButton()}</div>
      </li>
    );
  }

 
  function newAlbumButton() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "flex-start",
          flexDirection: "column",
          // backgroundColor: "white",
          // color: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            dubbing project
          </div>
          <StandardButton
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
            mxt_green
            onClick={() => newCassette(`untitled ${drafts.length}`)}
          >
            +
          </StandardButton>
        </div>
      </div>
    );
  }


  useEffect(() => {
    const token = localStorage.getItem("token");
    APIConfig.token = token;
    loadDrafts();
    
    var name, email, photoUrl, uid, emailVerified;
    var user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        

        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {

          });
      }
    });
  }, []);
  return (
    <div>
      <GridContainer className="center">
        <div className="padded">
          {artistName != "" && artistName != "no artist" ? null : null}
          <div className="row">
          </div>
        </div>
        <Container className="center">
        {draftingPanel()}
        </Container>
      </GridContainer>

    </div>
  );
};

export default CassetteDraftingView;
