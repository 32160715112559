import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { CogIcon } from '@heroicons/react/24/outline';
import APIConfig from '../../APIConfig';

const MenuItems = ({ user, altAuthPath, isRoot, isOpen }) => {
  const location = useLocation();
  const menuItemsClass = isOpen ? 'block md:inline-flex space-x-4' : 'hidden md:inline-flex space-x-4';

  // Check if the current location path starts with /e/
  const onEventPage = location.pathname.startsWith('/e/');
  // const showKonsoleButton = APIConfig.airtrak() is true
  const showKonsoleButton = user && (APIConfig.airtrak() || APIConfig.studio()) ;

  return (
    <div className={menuItemsClass}>
      {/* ... (other menu items) */}
      {showKonsoleButton && (
        <NavLink
          to="/konsole"
          className="font-theFutureMonoRegular text-sm text-white dark:text-gh_charcoal_foreground_primary-100 mx-2"
          activeClassName="text-gh_blue"
        >
          konsole
        </NavLink>
      )}
      {user && (
        <NavLink
          to="/settings"
          className="font-theFutureMonoRegular text-sm text-white dark:text-gh_charcoal_foreground_primary-100 mx-2"
          activeClassName="text-gh_blue"
        >
          <CogIcon className="h-5 w-5" />
        </NavLink>
      )}
     
      {!user && (isRoot || onEventPage) && (
        <>
          <NavLink
            to={{
              pathname: "/signin",
              state: { from: location.pathname } // passing the current path
            }}
            className="font-theFutureMonoRegular text-sm text-white dark:text-gh_charcoal_foreground_primary-100 mx-2"
            activeClassName="text-gh_blue"
          >
            sign in
          </NavLink>

        </>
      )}
      {!user && altAuthPath && !isRoot && !onEventPage && (
        <NavLink
          to={altAuthPath.path}
          className="font-theFutureMonoRegular text-sm text-white dark:text-gh_charcoal_foreground_primary-100"
          activeClassName="text-gh_blue"
        >
          {altAuthPath.text}
        </NavLink>
      )}
     
    </div>
  );
};

export default MenuItems;
