import React, { useState, useEffect } from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid'; // Ensure you have these icons installed

const ArtistSetTimesView = ({ eventId }) => {
    const [artistSets, setArtistSets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const numberOfDefaultArtists = 2; // Number of artists to display by default
// Function to convert 24-hour time to 12-hour time
const to12HourFormat = (time) => {
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    const suffix = hours >= 12 ? 'PM' : 'AM';
    hours = ((hours + 11) % 12) + 1; // Convert 00 and 12-hour format to 12-hour format
    return `${hours.toString().padStart(2, '')}${suffix}`;
  };
  
    useEffect(() => {
        // Dummy data for artist set times
        const dummyData = [
            { artistName: "DJ Delila", startTime: to12HourFormat("21:00"), endTime: to12HourFormat("22:00") },
            { artistName: "DJ Rest My Legs", startTime: to12HourFormat("22:00"), endTime: to12HourFormat("00:00") },
            { artistName: "DJ DotDotDash", startTime: to12HourFormat("00:00"), endTime: to12HourFormat("01:00") },
            { artistName: "ScaL", startTime: to12HourFormat("01:00"), endTime: to12HourFormat("03:00") },
          ];

        // Simulate data fetching delay
        setTimeout(() => {
            setArtistSets(dummyData);
            setIsLoading(false);
        }, 1000);
    }, [eventId]);

    if (isLoading) {
        return <div className="text-center text-lg text-gray-300">Loading set times...</div>;
    }

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
        // Only scroll when expanding
        if (!isExpanded) {
            setTimeout(() => {
                document.getElementById('artist-set-times-expand').scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }, 100);
        }
    };

    return (
        <div id="artist-set-times-view" className="bg-gh_charcoal_secondary-200 text-blue-300 font-scopeMono p-4 shadow-lg">
            
            <ul className="space-y-2">
                {artistSets.slice(0, isExpanded ? artistSets.length : numberOfDefaultArtists).map((set, index) => (
                    <li key={index} className="flex justify-between items-center">
                        <span className="font-medium text-md font-theFutureRegular">{set.artistName}</span>
                        <span className="text-sm text-gray-400 font-theFutureMonoRegular">{set.startTime} - {set.endTime}</span>
                    </li>
                ))}
            </ul>
            {artistSets.length > numberOfDefaultArtists && (
                <div className="flex justify-end items-center mt-2"> {/* Changed to justify-end here */}
                    <button
                        className="flex items-center text-blue-300 hover:text-blue-500"
                        onClick={handleToggle}
                    >
                        {isExpanded ? (
                            <MinusIcon className="h-5 w-4" />
                        ) : (
                            <PlusIcon className="h-4 w-4" />
                        )}
                        <span className="text-sm font-theFutureMonoRegular ml-2 pb-1.5">{isExpanded ? 'see less' : 'see more'}</span>
                    </button>
                </div>
            )}

            {/* Spacer div for scrolling target */}
            <div id="artist-set-times-expand" className={isExpanded ? 'mt-2' : 'hidden'} />
        </div>
    );
};

export default ArtistSetTimesView;
