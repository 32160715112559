import React from 'react';
import { CalendarIcon } from "@heroicons/react/24/solid";

import moment from 'moment-timezone';

function prettyPrintDate(dateString) {
    var date = moment(dateString).tz('America/New_York'); // Convert to New York time zone, or use moment.tz.guess() for browser's time zone
    var formattedDate = date.format('MMMM D, YYYY'); // Formats date in 'Month Day, Year' format
    return formattedDate;
}

const EventDate = ({ date }) => (
    <p className="flex items-center font-theFutureMonoRegular text-sm py-1">
        <CalendarIcon className="h-5 w-5 mr-1" />
        {prettyPrintDate(date)}
    </p>
);

export default EventDate;
