// SpeechBubble.jsx

import React from 'react';

const SpeechBubble = ({ children }) => {
  return (
    <div className="speech-bubble border border-1 border-blue-400 p-2">
      <p className="text-sm font-theFutureMonoRegular text-blue-400">{children}</p>
    </div>
  );
};

export default SpeechBubble;
