// useRSVPToggle.js
import { useCallback, useState } from "react";
import events from "../../../services/events";

const useRSVPToggle = ({ going, setGoing, event, token, increment, decrement, notify }) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleGoing = useCallback(() => {
    if (isLoading) {
      return; // Prevent further clicks if we're already loading
    }

    setIsLoading(true); // Start loading
    const action = going ? "unrsvp" : "rsvp";

    events.rsvp(event.id, token, action).then(() => {
      if (going) {
        decrement();
        notify("You are no longer going.", false);
      } else {
        increment();
        notify("You are now going!", true);
      }
      setGoing(!going);
    }).catch((error) => {
      console.error("RSVP action failed:", error);
      notify("An error occurred.", going);
    }).finally(() => {
      setIsLoading(false); // Stop loading regardless of outcome
    });
  }, [going, isLoading, event.id, token, increment, decrement, notify, setGoing]);

  return { toggleGoing, isLoading };
};

export default useRSVPToggle;
