// AddToCalendarCustomButton.jsx
import React from 'react';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

const AddToCalendarCustomButton = ({ event }) => {
  // Define the custom styling for the button here
  const customStyle = {
    border: "2px solid #d53f8c",
    borderRadius: "0",
    padding: "0.5rem 1rem",
    fontSize: "1rem",
    fontWeight: "400",
    fontFamily: "'Your Custom Font Family', sans-serif",
    color: "#d53f8c",
    background: "transparent",
    hoverBackground: "#d53f8c",
    hoverColor: "#ffffff",
    activeBackground: "#d53f8c",
    activeColor: "#ffffff",
    cursor: "pointer",
  };

  // If you have additional complex logic or calculations for your event object,
  // you can use the useMemo hook here to memoize the results.

  return (
    <div className="-m-2">
      <AddToCalendarButton
        name={event.name}
        startDate={event.date}
        startTime={event.time}
        endTime="23:59"
        timeZone="America/New_York"
        description={event.description}
        location={event.address}
        lightMode={"dark"}
        label="add to calendar"
        buttonStyle="text"
        options={["Apple", "Google", "Yahoo", "iCal"]}
        {...customStyle} // Spread the customStyle object here
      />
    </div>
  );
};

export default AddToCalendarCustomButton;
