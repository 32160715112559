import { createContext, useState, useEffect } from "react";
import AudioPlayerService from "../../artist_dashboard/services/AudioPlayerService";

const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
  const [src, setSrc] = useState({
    title: "",
    artist: "",
    color: "",
    image: "",
    audioSrc: "",
  });

  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0); // State for current time
  const [duration, setDuration] = useState(0); // State for duration
  const [volume, setVolume] = useState(1); // State for volume (1 is 100%)

  useEffect(() => {
    const onAudioEnded = () => setPlaying(false);
    const onTimeUpdate = () => setCurrentTime(AudioPlayerService.audio.currentTime);
    const onLoadedMetadata = () => setDuration(AudioPlayerService.audio.duration);

    AudioPlayerService.on("ended", onAudioEnded);
    AudioPlayerService.on("timeupdate", onTimeUpdate);
    AudioPlayerService.on("loadedmetadata", onLoadedMetadata);

    // Set the initial volume
    AudioPlayerService.audio.volume = volume;

    return () => {
      AudioPlayerService.off("ended", onAudioEnded);
      AudioPlayerService.off("timeupdate", onTimeUpdate);
      AudioPlayerService.off("loadedmetadata", onLoadedMetadata);
    };
  }, [volume]); // Add volume to the dependency array so the effect runs when volume changes

  const playAudio = (newSrc) => {
    setSrc({ ...newSrc });
    AudioPlayerService.load(newSrc.audioSrc);
    setPlaying(true);
  };

  const pauseAudio = () => {
    AudioPlayerService.pause();
    setPlaying(false);
  };

  const toggleAudio = () => {
    AudioPlayerService.togglePlay();
    setPlaying(!playing);
  };

  const changeVolume = (newVolume) => {
    setVolume(newVolume);
    AudioPlayerService.audio.volume = newVolume;
  };

  return (
    <AudioPlayerContext.Provider
      value={{
        src,
        playing,
        playAudio,
        pauseAudio,
        toggleAudio,
        currentTime,
        setCurrentTime,
        duration,
        setDuration, // Provide setDuration in the context
        volume,
        setVolume: changeVolume
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerContext;
