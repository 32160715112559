import React, { useState, useContext } from "react";
import { WallContext } from "../contexts/WallContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from 'firebase/auth';
import firebase from "firebase/compat/app";

const auth = getAuth(firebase.app());

const PostForm = () => {
  const { createPost } = useContext(WallContext);
  const [content, setContent] = useState("");
  const [user] = useAuthState(auth);
  const characterLimit = 140;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (user) {
      const userId = user.uid;
      const username = user.displayName || "unknown";
      const profilePicture = user.photoURL || "";

      createPost(content, { userId, username, profilePicture });
      setContent("");
    } else {
      alert("Please log in to create a post.");
    }
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  return (
    <div className="bg-gh_charcoal_secondary-200  font-theFutureMonoRegular p-4 rounded-none">
      <form onSubmit={handleSubmit} className="flex flex-col">
        <textarea
          value={content}
          onChange={handleContentChange}
          placeholder="say something..."
          className="resize-none p-3 h-24 bg-gh_charcoal_secondary-100 text-blue-200 placeholder-gray-500 border-0 focus:outline-none"
          maxLength={characterLimit}
        />
        <div className="flex justify-between items-center mt-2  bg-gh_charcoal_secondary-200 text-white">
          <span className={`text-sm ${content.length === characterLimit ? 'text-red-500' : 'text-gray-500'}`}>
            {content.length}/{characterLimit}
          </span>
          {content.trim() && (
            <button 
              type="submit" 
              className="bg-blue-400 text-white px-4 py-2 hover:bg-blue-600 transition-colors duration-200 ease-in-out focus:outline-none focus:bg-blue-700"
            >
              post
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default PostForm;
