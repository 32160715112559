import React, { useState } from "react";
import AvatarView from "./AvatarView";

const HostedByView = ({ event }) => {
  const [showAll, setShowAll] = useState(false);
  const admins = event?.admins?.users?.length
    ? event.admins.users
    : [event.creator];

  const maxVisibleAdmins = 5;

  if (!admins.length) {
    return null;
  }

  const handleSeeMoreClick = () => {
    setShowAll(!showAll);
  };

  const displayAdmins = showAll ? admins : admins.slice(0, maxVisibleAdmins);

  return (
    <div className="flex items-center bg-gh_charcoal_secondary-200 text-white shadow-lg sm:p-4 p-2 mx-auto overflow-hidden">
      <div className="text-sm font-theFutureMonoRegular mr-4 whitespace-nowrap">hosted by</div>
      <div className="flex space-x-2 overflow-x-auto">
        {displayAdmins.map((admin, index) => (
          <AvatarView
            key={index}
            username={admin.username}
            profilePic={admin.profilePicUrl}
            showUsername={false}
            showUsernameOnHover={true}
          />
        ))}
      </div>
      {admins.length > maxVisibleAdmins && (
        <button
          className="mt-4 px-4 bg-gray-700 text-white hover:bg-gray-600"
          onClick={handleSeeMoreClick}
        >
          {showAll ? "See Less" : "See More"}
        </button>
      )}
    </div>
  );
};

export default HostedByView;
