import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import api from '../../../api';

const AddArtistComponent = () => {
  const [artistName, setArtistName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useAuthState(getAuth());

  const handleAddArtist = async () => {
    if (!artistName.trim()) return;
    setIsLoading(true);
    try {
      const response = await api.newArtist(artistName, user?.uid);
      const newArtist = await response.json();
      setIsLoading(false);
      setArtistName('');
      // Handle success, e.g., show a notification or toast
    } catch (error) {
      console.error('Error adding new artist:', error);
      setIsLoading(false);
      // Handle error, e.g., show an error message or modal
    }
  };

  return (
    <div className="mt-4 mb-6">
      <div className="flex items-center space-x-3">
        <input
          type="text"
          value={artistName}
          onChange={(e) => setArtistName(e.target.value)}
          placeholder="New artist name"
          className="flex-grow text-gray-700 p-3 border border-gray-200 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          disabled={isLoading || !user}
        />
        <button
          onClick={handleAddArtist}
          className={`transition duration-150 ease-in-out transform hover:scale-105 bg-blue-500 text-white rounded-md px-6 py-2 shadow-sm focus:outline-none focus:ring focus:ring-blue-300 ${
            isLoading || !artistName.trim() || !user ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isLoading || !artistName.trim() || !user}
        >
          {isLoading ? (
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
          ) : (
            'Add Artist'
          )}
        </button>
      </div>
    </div>
  );
};

export default AddArtistComponent;
