// NameEntryForm.jsx

import React, { useState } from "react";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import auth from "../../services/auth";
import events, { default as event_service } from "../../services/events";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import { async } from "browser-image-compressor";
import WideButton from "../buttons/WideButton";
import ButtonTest from "../buttons/ButtonTest";
import NiceButton from "../buttons/NiceButton";

const NameEntryForm = (props) => {
  //   const [name, setName] = useState("");
  let history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log("name data: ", data);
    setFirstName(data.firstName);
    setLastName(data.lastName);
    console.log("firstName", data.firstName);
    console.log("lastName", data.lastName);
    // e.preventDefault();
    setLoading(true);
    // await
    updateName(data.firstName, data.lastName, token);
    // console.log(data);
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState("");
  const [token, setToken] = useState("");
  const [nameUpdated, setNameUpdated] = useState(false);
  const location = useLocation();
  const db = firebase.firestore();
  const [inputValid, setInputValid] = useState(false);
  useEffect(() => {
    if (location.state) {
      console.log("location.state", location.state);
    }
  }, [location, firstName, lastName]);

  //   async version

  const validateInput = async () => {
    console.log("validating input");
    console.log("firstName", firstName);
    console.log("lastName", lastName);
    // check to see that this is a name (no numbers or special characters besides - and ')
    // allows for name like O'Neil
    // allows for last name to be one character

    const nameRegex = /^[a-zA-Z-' ]+$/;
    if (firstName.length > 0 && lastName.length > 0) {
      console.log("input is valid");
      return true;
      // if (nameRegex.test(firstName) && nameRegex.test(lastName)) {
      //   console.log("input is valid");
      //   // setInputValid(true);
      //   return true;
      // } else {
      //   console.log("input is invalid");
      //   // setInputValid(false);
      //   return false;
      // }
      // setInputValid(true);
    } else {
      console.log("input is invalid");
      // setInputValid(false);
      return false;
    }
  };
  const navigateToStoredEvent = (event) => {
    // previous view had passed in an event object like so
    // history.push( {
    //     pathname: "/name_entry/",
    //     state: { eventData: location.state.eventData }
    //   });
    if (location.state.eventData.id) {
      // event id is stored in location.state.eventData.id

      // navigate to event page
      console.log("event id is: ", location.state.eventData.id);
      const event_id = location.state.eventData.id;

      history
        .push({
          pathname: `/e/${event_id}`,
        })
        .then(() => {
          window.location.reload();
        });
    } else {
      // navigate to home page
      history
        .push({
          pathname: "/",
        })
        .then(() => {
          window.location.reload();
        });
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (token) {
            setToken(token);
          })
          .catch(function (error) {
            console.log(error);
          })
          .then(() => {
            setLoading(false);
          });

        var uid = user.uid;
        setUid(uid);
      }
    });
  }, []);

  async function updateName(firstName, lastName, token) {
    setLoading(true);
    auth.updateName(firstName, lastName, token).then(async (response) => {
      setLoading(false);
      if (response) {
        // Instead of updating a "nameUpdated" state, navigate directly
        await continueToEvent(); // Added await here to ensure it finishes before any other logic runs
      } else {
        console.log("name not submitted");
      }
    });
}


  //   async continue const function

  async function continueToEvent() {
    // navigateToStoredEvent();
    console.log("continueToEvent() called");
    setLoading(true);
    const event_id = location.state.eventData.id;
    console.log("event_id", event_id);
    const e = await event_service.get(event_id, token);
    console.log("e", e);
    if (e) {
      // navigate to event page
      console.log("event id is: ", e.id);
      const event_id = e.id;
      console.log("event data is: ", e);

      history.push({
        pathname: `/e/${event_id}`,
        state: { eventData: e },
      });
    } else {
      // navigate to home page
      alert("no event found");
      // console.log("no event found");
      // history
      //   .push({
      //     pathname: "/",
      //   })
      //   .then(() => {
      //     window.location.reload();
      //   });
    }
  }

  const getContinueButton = () => {
    return (
      <NiceButton isLoading={loading} onClick={() => continueToEvent()}>
        continue
      </NiceButton>
    );
  };

  const proceedAnonymously = async () => {
    // navigateToStoredEvent();
    console.log("proceedAnonymously() called");
    setFirstName("anonymous");
    setLastName("anonymous");
    setLoading(true);
    await updateName(firstName, lastName, token);
  };

  const getAnonymousView = () => {
    return (
      <div>
        <div className="py-10"></div>

        <div className="py-5 font-theFutureExtraLight">
          don't want to give a name...?
        </div>
        <NiceButton isLoading={loading} onClick={() => proceedAnonymously}>
          <div className="text-xs">proceed anonymously</div>
        </NiceButton>
      </div>
      // <div>
      //   <div className="py-5">don't want to give a name...?</div>
      //   <WideButton
      //     text={"proceed anonymously"}
      //     // loading={loading}
      //     lambda={() => {
      //       proceedAnonymously();
      //     }}
      //   ></WideButton>
      // </div>
    );
  };

  const captionView = () => {
    return (
      <div className="py-10 font-theFutureExtraLight w-2/3">
        please enter first and last name. names need to be a minimum of two
        characters.
      </div>
    );
  };

  const getSubmitButton = () => {
    if (inputValid) {
      return (
        <div>
          <NiceButton isLoading={loading} onClick={() => handleSubmit}>
            submit
          </NiceButton>
        </div>
      );
    } else {
      return null;
    }
  };

  const continueAfterNameUpdateView = () => {
    return (
      <div className="flex flex-col items-center justify-center font-theFutureMonoRegular ">
        <div className="dark:text-green-300">
          your information has been saved. click continue to go to the event
          page.
        </div>
        {/* <div className="text-2xl font-bold text-gray-800 dark:text-white">
          name updated
        </div> */}

        <div className="py-5">
          {/* get button if valid */}
          {getContinueButton()}
        </div>
      </div>
    );
  };

  const handleAnonSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // update name
    // use the updateName(firstName, lastName, token) function asynchonously to update the name
    // then, call props.onNameSubmitted(firstName + " " + lastName) to update the name in the parent component
    // then, redirect to the dashboard
    // console.log("name submitted: ", firstName + " " + lastName);
    // console.log("token: ", token);
    await updateName("anonymous", "anonymous", token);

    // redirect to dashboard while passing location.state
    // props.history.push({
    //     pathname: "/dashboard",
    //     state: location.state,
    //     });

    // console.log("location: ", location);
  };

  const firstNameHandler = async (e) => {
    console.log("validateInput() called");

    setFirstName(e.target.value);
    const s = await validateInput();
    // validateInput();
    console.log("s: ", s);
    if (s) {
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  };

  const lastNameHandler = async (e) => {
    console.log("validateInput() called");

    setLastName(e.target.value);
    const s = await validateInput();
    // validateInput();
    console.log("s: ", s);
    if (s) {
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  };
  const handleSubmissst = async (e) => {
    e.preventDefault();
    setLoading(true);

    // update name
    // use the updateName(firstName, lastName, token) function asynchonously to update the name
    // then, call props.onNameSubmitted(firstName + " " + lastName) to update the name in the parent component
    // then, redirect to the dashboard
    // console.log("name submitted: ", firstName + " " + lastName);
    // console.log("token: ", token);
    await updateName(firstName, lastName, token);

    // redirect to dashboard while passing location.state
    // props.history.push({
    //     pathname: "/dashboard",
    //     state: location.state,
    //     });

    // console.log("location: ", location);
  };

  const nameEntryView = () => {
    if (!nameUpdated) {
      return (
        // stacked vertically
        <div className="flex flex-col items-center justify-center font-theFutureMonoRegular">
          {/* <form onSubmit={handleSubmit(onSubmit)}>
            
          
            <input type="submit" />
          </form> */}

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* center everything */}
            <div className="font-theFutureMonoRegular py-2">
              <label htmlFor="name" className="dark:">
                what's your name?
              </label>
              {/* vertically stack */}
              <div className="flex flex-col">
                {/* input is clear background with pink text  and border of 2px*/}
                <label htmlFor="firstName" className="p-2">
                  First name
                </label>
                <input
                  id="firstName"
                  className="form-control bg-transparent  border-pink-300 border-2 p-2"
                  aria-invalid={errors.firstName ? "true" : "false"}
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <span role="alert" className="text-red-500">
                    This field is required
                  </span>
                )}

                <label htmlFor="lastName" className="p-2">
                  Last name
                </label>
                <input
                  id="lastName"
                  className="form-control bg-transparent  border-pink-300 border-2 p-2"
                  aria-invalid={errors.lastName ? "true" : "false"}
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <span role="alert" className="text-red-500">
                    This field is required
                  </span>
                )}
              </div>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            {/* center horizontally */}
            {/* <div className="flex justify-center">
                
              </div> */}
            <NiceButton isLoading={loading}>
              <input type="submit" />
            </NiceButton>
            {/* <input type="submit" /> */}
            {/* {getSubmitButton()} */}
            {/* <NiceButton
          isLoading={loading}
          onClick={() => handleSubmit}
        >
          submit
        </NiceButton>  */}
          </form>
        </div>
      );
    } else {
      return null;
    }
  };
  // {nameUpdated && continueAfterNameUpdateView()}
  const showView = () => {
    if (nameUpdated) {
      return continueAfterNameUpdateView();
    } else {
      return nameEntryView();
    }
  };

  return (
    <div>
      {/* <ButtonTest>
      
      </ButtonTest> */}
      {showView()}
      {/* <FirstNameFormField></FirstNameFormField> */}
      {/* {captionView()} */}
    </div>
  );
};

export default NameEntryForm;
