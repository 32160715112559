// services/WallPostsService.js

import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";

const db = firebase.firestore();

export const getWallPosts = async (eventId) => {
  try {
    console.log(`Fetching wall posts for event ${eventId}...`);
    const snapshot = await db.collection(`/events/${eventId}/wall`).get();
    const wallPosts = snapshot.docs.map(doc => {
      const postData = doc.data();
      console.log(`Post ID: ${doc.id}`, postData); // Log the entire post data
      return postData;
    });
    console.log(`Fetched ${wallPosts.length} wall posts for event ${eventId}.`);
    return wallPosts;
  } catch (error) {
    console.error(`Error fetching wall posts for event ${eventId}:`, error);
    throw error;
  }
};

export const addWallPost = async (eventId, post) => {
  try {
    console.log(`Adding wall post for event ${eventId}:`, post);
    const docRef = await db.collection(`/events/${eventId}/wall`).add(post);
    console.log(`Added wall post with ID: ${docRef.id} for event ${eventId}.`);
    return docRef.id;
  } catch (error) {
    console.error(`Error adding wall post for event ${eventId}:`, error);
    throw error;
  }
};
