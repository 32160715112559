// CreateButton.jsx
import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import DropdownItem from "../Dropdown/DropdownItem";
import {
  PlusIcon,
  ChevronDownIcon,
  FolderPlusIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";

const CreateButton = ({ createFolder, createSharedFolder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (callback) => {
    callback();
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen((prevState) => !prevState)}
        className="flex items-center text-blue-400 bg-white dark:hover:bg-gh_charcoal_tertiary-100 font-bold py-2 px-4 dark:bg-gh_charcoal_secondary-100 mr-3 shadow-lg rounded"
      >
        <PlusIcon className="h-5 w-5" />
        <span className="ml-1 font-theFutureMonoRegular mb-1">create</span>
        <ChevronDownIcon className="h-5 w-5 ml-1" />
      </button>
      <div
        className={`absolute bg-white mt-2 py-2 w-48 rounded-md shadow-xl ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <DropdownItem onClick={() => handleItemClick(createFolder)}>
          <span className="flex items-center">
            <FolderIcon className="h-5 w-5 mr-2" />
            Folder
          </span>
        </DropdownItem>
        <DropdownItem onClick={() => handleItemClick(createSharedFolder)}>
          <span className="flex items-center">
            <FolderPlusIcon className="h-5 w-5 mr-2" />
            Shared Folder
          </span>
        </DropdownItem>
      </div>
    </div>
  );
};

export default CreateButton;