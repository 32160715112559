import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { firebaseAuth } from "../../provider/AuthProvider";
import Container from "../themes/Container";
import SettingsContainer from "./SettingsContainer";
import GridContainer from "../themes/GridContainer";
import UserInfoDisplay from "./UserInfoDisplay";
import UpdateUserInfoForm from "./UpdateUserInfoForm";
import ContactEmail from "./ContactEmail";
import useUserProfile from "../../hooks/useUserProfile";
import WideButton from "../buttons/WideButton";
import Handles from "rc-slider/lib/Handles";
import StorageUsage from "../file_mgmt/drive/hooks/StorageUsage";

import APIConfig from "../../APIConfig";
import UsernameEditView from "../../auth/UsernameEditView";
import ProfileView from "../events/profiles/ProfileView";
import ArtistRegistrationForm from "../forms/ArtistRegistrationForm";
import ArtistForm from "../events/hooks/ArtistForm";
function Settings() {
  const { handleSignout } = useContext(firebaseAuth);
  const {
    artistName,
    isArtist,
    hasStudio,
    artistLink,
    stripeDashLink,
    isSeller,
  } = useUserProfile();

  const displayStorage = () => {
    if (APIConfig.airtrak() === true || APIConfig.studio() === true) {
      return (
        <div className="border-1 border-gh_charcoal_foreground_secondary-100 p-6 rounded-lg">
          {/* <ArtistForm /> */}
          <StorageUsage />
          <ProfileView> </ProfileView>
        </div>
      );
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-4xl font-semibold mb-8">settings</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="border-1  border-gh_charcoal_foreground_secondary-100 p-6 rounded-lg">
          <UserInfoDisplay
            user={{
              artistName,
              isArtist,
              hasStudio,
              isSeller,
              artistLink,
              stripeDashLink,
            }}
          />
        </div>

        {displayStorage()}
      </div>
      <div class="py-2">
        <WideButton text={"sign out"} lambda={handleSignout} />
        <ContactEmail email="samir@dotmixtape.com" />
      </div>
    </div>
  );
}

export default Settings;
