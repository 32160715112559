// AttendingModalAttendeeListView.jsx
import React from "react";
import UserDisplay from "./UserDisplay"; // Make sure the path is correct

const AttendingModalAttendeeListView = ({ event }) => (
  <div className="max-h-[400px] overflow-y-auto"> {/* Add scrollable container */}
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
      {event.attending.users.map((user, index) => (
        <UserDisplay
          key={index}
          username={user.username}
          profilePic={user.profilePicUrl}
        />
      ))}
    </div>
  </div>
);

export default AttendingModalAttendeeListView;
