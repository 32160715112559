const AlbumsSkeletonGrid = () => {
  return (
    <div className="grid grid-cols-3 gap-4 p-4">
      {[...Array(6)].map((_, i) => ( // Adjust the number of placeholders as needed
        <div key={i} className="animate-pulse border rounded shadow-lg overflow-hidden">
          <div className="w-full bg-gray-300" style={{ height: '200px' }}></div> {/* Mimic the image */}
          <div className="p-2">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div> {/* Mimic the title */}
            <div className="h-4 bg-gray-300 rounded w-1/2 mt-2"></div> {/* Additional placeholder lines */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AlbumsSkeletonGrid;