import React, { useState } from "react";
import firebase from "firebase/compat/app";
import { useAuthState } from "react-firebase-hooks/auth";

function UserInfoDisplay() {
  const auth = firebase.auth();
  const [user, loading] = useAuthState(auth);
  const [isEditing, setIsEditing] = useState(false);

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const displayValue = (value) => {
    return value || <span className="italic text-gray-400">Not set</span>;
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-lg font-semibold">Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4 flex items-center justify-between">
        User Information
        {/* <button
          className="bg-blue-600 text-white px-2 py-1 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          onClick={toggleEditMode}
        >
          {isEditing ? "Done" : "Edit"}
        </button> */}
      </h2>
      <ul className="space-y-2">
        {/* <li>
          <span className="font-theFutureMonoRegular">Artist Name:</span>{" "}
          {displayValue(user.artistName)}
        </li> */}
        <li>
          <span className="font-theFutureMonoRegular">Email:</span>{" "}
          {displayValue(user.email)}
        </li>
        <li>
          <span className="font-theFutureMonoRegular">Phone:</span>{" "}
          {displayValue(user.phoneNumber)}
        </li>
        {/* 
        <li>
          <span className="font-theFutureMonoRegular">User ID:</span>{" "}
          {displayValue(user.userId)}
        </li>
        <li>
          <span className="font-theFutureMonoRegular">Artist ID:</span>{" "}
          {displayValue(user.artistId)}
        </li>
        <li>
          <span className="font-theFutureMonoRegular">Theme:</span>{" "}
          {displayValue(user.theme)}
        </li>
        <li>
          <span className="font-theFutureMonoRegular">Is Artist:</span>{" "}
          {user.isArtist ? "Yes" : "No"}
        </li>
        <li>
          <span className="font-theFutureMonoRegular">Has Studio:</span>{" "}
          {user.hasStudio ? "Yes" : "No"}
        </li>
        <li>
          <span className="font-theFutureMonoRegular">Is Seller:</span>{" "}
          {user.isSeller ? "Yes" : "No"}
        </li>
        <li>
          <span className="font-theFutureMonoRegular">Artist Link:</span>{" "}
          {displayValue(user.artistLink)}
        </li> */}
        {/* <li>
          <span className="font-theFutureMonoRegular">
            Stripe Dashboard Link:
          </span>{" "}
          {displayValue(user.stripeDashLink)}
        </li> */}
      </ul>
    </div>
  );
}

export default UserInfoDisplay;
