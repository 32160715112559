import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import APIConfig from '../../../APIConfig'; // Adjust the path as needed

const useFetchAlbumsData = (pageNumber = 1) => {
  const [albumsData, setAlbumsData] = useState({
    results: [],
    nextPage: null,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, loadingAuth, errorAuth] = useAuthState(firebase.auth());

  useEffect(() => {
    const fetchAlbumsData = async () => {
      if (loadingAuth) return; 
      if (errorAuth) {
        setError(errorAuth);
        return;
      }
      if (!user) {
        setError(new Error('No authenticated user found'));
        return;
      }

      setLoading(true);
      try {
        const idToken = await user.getIdToken(true);
        const endpoint = `${APIConfig.albums()}?page=${pageNumber}`;

        const response = await fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${idToken}`,
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setAlbumsData({
          results: data.results,
          nextPage: data.next,
          prevPage: data.previous,
          totalCount: data.count,
          totalPages: Math.ceil(data.count / (data.results.length || 1))
        });
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchAlbumsData();
  }, [user, loadingAuth, errorAuth, pageNumber]);

  return { albumsData, loading, error };
};

export default useFetchAlbumsData;
