import React from "react";
import FBUISignin from "../../auth/FBUISignin";

export default function ModalAuthView(props) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <button
        className="w-full h-12 text-white bg-blue-400 hover:bg-blue-500 active:bg-blue-600 font-theFutureMonoRegular text-lg uppercase shadow hover:shadow-lg transition duration-150 ease-in-out"
        type="button"
        onClick={() => setShowModal(true)}
      >
        RSVP
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full max-w-md my-6 mx-auto">
              {/* content */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-2xl text-center font-semibold w-full text-gh_charcoal_secondary-200 font-theFutureRegular">
                    Sign up or sign in to RSVP
                  </h3>
                  <button
                    className="absolute top-5 right-5 bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-black opacity-50 h-6 w-6 text-2xl block">×</span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto">
                  <FBUISignin event={props.event} />
                </div>
                {/* footer */}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
