// import APIConfig from "./APIConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import APIConfig from "../APIConfig";
import utils from "../components/artist_dashboard/utilities/utils";
import { authMethods } from "../firebase/authmethods";

// import { authMethods } from "./firebase/authmethods";

// import { authMethods } from "../../firebase/authmethods";
const token = localStorage.getItem("token");
// const b = APIConfig.baseURL();
// ${APIConfig.baseURL()}
// const base = APIConfig.baseURL()
let db = firebase.firestore();
var user = firebase.auth().currentUser;
// var tapes_ref = firebase
//   .firestore()
//   .collection("studio")
//   .doc(firebase.auth().currentUser.uid)
//   .collection("tapes");
const authUser = Object.keys(window.localStorage).filter((item) =>
  item.startsWith("firebase:authUser")
)[0];
const getCurrentUser = () => firebase.auth().currentUser;
const setStudioData = (downloadURL, uid, filename) => {
  db.collection("studio")
    .doc(uid)
    .collection("tapes")
    .add({
      filename: filename,
      url: downloadURL,
    })
    .then(function (docRef) {
      ////("Document written with ID: ", docRef.id);
    })
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
};
const getStudioData = (downloadURL, uid, filename) => {
  db.collection("studio")
    .doc(uid)
    .collection("tapes")
    .add({
      filename: filename,
      url: downloadURL,
    })
    .then(function (docRef) {
      ////("Document written with ID: ", docRef.id);
    })
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });
};

const getShareableLink = async (tape, uid, token) => {
  // make a request to the APIConfig.share_tape endpoint
  // and return the shareable link using async await and headers 
  var headers = {
    "Content-Type": "application/json",
    Authorization: "JWT " + token,
  };
  var data = {
    tape_uid: tape.id,
    user_uid: uid,
  };
  // url is `${APIConfig.share_tape}}`
  const url = `${APIConfig.share_tape()}`;
  console.log("the url is ", url);
  // headers print
  console.log("the headers are ", headers);
  // data print
  console.log("the data is ", data);

  // const response = await fetch(resource[, options]);
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  });
  const json = await response.json();
  ////(json);
  return json;
}


const getTapesRef = () => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.

      var tapes_ref = db.collection("studio").doc(user.uid).collection("tapes");
      return tapes_ref;
      //   ////(user.uid);
    } else {
      return null;
      // User not logged in or has just logged out.
    }
  });
};
const test = (downloadURL, uid, filename) => {
  var tapes_ref = getTapesRef();
  if (tapes_ref) {
    ////("we hittin");
    tapes_ref.onSnapshot(function (doc) {
      ////("Current data: ", doc.data());
    });
  }
};
const myTapesObserver = (uid) => {
  var tapes_ref = db.collection("studio").doc(uid).collection("tapes");
  tapes_ref.onSnapshot(function (querySnapshot) {
    var tapes = [];
    querySnapshot.forEach(function (doc) {
      tapes.push(doc.data().filename);
    });
    ////("current tapes: ", tapes.join(", "));
  });
};

function makeRequest(location) {
  return new Promise((resolve, reject) => {
    ////(`Making request to ${location}`);
    if (location === "google") {
      resolve("google says hi");
    } else {
      reject("we can only talk to google");
    }
  });
}

function getFBData() {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var files_ref = db.collection("studio").doc(uid).collection("files");

        var files = [];
        var data = {};
        var tasks = {};
        var columns = {};
        var columnOrder = [];
        var _ids = [];
        files_ref.onSnapshot(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            files.push(doc.data().filename);
            tasks[doc.id] = {
              id: doc.id,
              content: doc.data().filename,
              compressed_filename: doc.data().filename,
              url: doc.data().url,
            };
            _ids.push(doc.id);
          });
          columns["column-1"] = {
            id: "column-1",
            title: "/files",
            taskIds: _ids,
          };
          columns["column-2"] = {
            id: "column-2",
            title: "/.mixtape",
            taskIds: [],
          };

          columnOrder = ["column-1", "column-2"];
          data["tasks"] = tasks;
          data["columns"] = columns;
          data["columnOrder"] = columnOrder;
          resolve(data);
        });
      } else {
        ////("signed out....");
        reject("error signed out");
      }
    });
  });
}

function getJustFilesData(album) {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var files_ref = db.collection("studio").doc(uid).collection("files");

        var files = [];
        var data = {};
        var tasks = {};
        var columns = {};
        var columnOrder = [];
        var _ids = [];
        files_ref.onSnapshot(function (querySnapshot) {
          files = [];

          querySnapshot.forEach(function (doc) {
            files.push(doc.data().filename);
            if (tasks[doc.id] == null) {
              tasks[doc.id] = {
                id: doc.id,
                content: doc.data().filename,
                compressed_filename: doc.data().compressed_filename,
                url: doc.data().url,
              };
              _ids.push(doc.id);
            }
          });
          columns["column-1"] = {
            id: "column-1",
            title: "/files",
            taskIds: _ids,
          };
          columns["column-2"] = {
            id: "column-2",
            // title: album.name,
            title: "tracks",
            taskIds: [],
          };

          columnOrder = ["column-1", "column-2"];

          data["tasks"] = tasks;
          data["columns"] = columns;
          data["columnOrder"] = columnOrder;
          resolve(data);
        });
      } else {
        ////("signed out....");
        reject("error signed out");
      }
    });
  });
}

function deleteAlbum(album) {
  const uid = localStorage.getItem("uid");
  var ref;
  let album_draft_ref = db.doc(`studio/${uid}/drafts/${album.docId}`);
  album_draft_ref.delete();
  return;
}
function deleteElement(element_id, location, album) {
  const uid = localStorage.getItem("uid");
  var ref;
  if (album) {
    let album_track_ref = db.doc(
      `studio/${uid}/drafts/${album.docId}/tracks/${element_id}`
    );
    album_track_ref.delete();
  } else {
    if (location === "column-2") {
      ref = db
        .collection("studio")
        .doc(uid)
        .collection("mxt")
        .doc("default")
        .collection("tracks")
        .doc(element_id)
        .delete();
    } else {
      ref = db
        .collection("studio")
        .doc(uid)
        .collection("files")
        .doc(element_id)
        .delete();
    }
  }
  return; ////(element_id, location);
}
function getMXTData() {
  return new Promise((resolve, reject) => {
    const uid = localStorage.getItem("uid");

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;

        localStorage.setItem("uid", uid);
        var mxt_ref = db
          .collection("studio")
          .doc(uid)
          .collection("mxt")
          .doc("default")
          .collection("tracks");

        var files = [];
        var data = {};
        var tasks = {};
        var columns = {};
        var columnOrder = [];
        var _ids = [];
        mxt_ref.onSnapshot(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            files.push(doc.data().filename);
            tasks[doc.id] = {
              id: doc.id,
              content: doc.data().filename,
              url: doc.data().url,
            };
            _ids.push(doc.id);
          });
          columns["column-2"] = {
            id: "column-2",
            title: "/.mixtape",
            taskIds: _ids,
          };
          // columns["column-2"] = {
          //   id: "column-2",
          //   title: "/.mixtape",
          //   taskIds: [],
          // };
          columnOrder = ["column-2"];
          data["tasks"] = tasks;
          data["columns"] = columns;
          data["columnOrder"] = columnOrder;
          resolve(data);
        });
      } else {
        ////("signed out....");

        reject("error signed out");
      }
    });
  });
}

function getADData(album) {
  return new Promise((resolve, reject) => {
    const uid = localStorage.getItem("uid");

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var now = firebase.firestore.Timestamp.now();

        localStorage.setItem("uid", uid);
        var mxt_ref = db
          .collection("studio")
          .doc(uid)
          .collection("drafts")
          .doc(album.docId)
          .collection("tracks");

        db.collection("studio")
          .doc(uid)
          .collection("drafts")
          .doc(album.docId)
          .update({
            last_edited: now,
          });
        var files = [];
        var data = {};
        var tasks = {};
        var columns = {};
        var columnOrder = [];
        var _ids = [];

        mxt_ref.orderBy("index", "asc").onSnapshot(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            files.push(doc.data().filename);
            tasks[doc.id] = {
              id: doc.id,
              content: doc.data().filename,
              compressed_filename: doc.data().compressed_filename,
              url: doc.data().url,
              trackName: doc.data().track_name,
              index: 0,
            };
            _ids.push(doc.id);
          });
          columns["column-2"] = {
            id: "column-2",
            // title: album.name,
            title: "tracks",
            taskIds: _ids,
          };

          columnOrder = ["column-2"];
          data["tasks"] = tasks;
          data["columns"] = columns;
          data["columnOrder"] = columnOrder;
          resolve(data);
        });
      } else {
        ////("signed out....");

        reject("error signed out");
      }
    });
  });
}


function getOtariProjectData(project) {
  return new Promise((resolve, reject) => {
    const uid = localStorage.getItem("uid");

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var now = firebase.firestore.Timestamp.now();

        localStorage.setItem("uid", uid);
        var pending_ref = db
          .collection("studio")
          .doc(uid)
          .collection("services")
          .doc("analog")
          .collection("otari")
          .doc(project.docId)
          .collection("pending");

        db.collection("studio")
          .doc(uid)
          .collection("services").doc("analog").collection("otari")
          .doc(project.docId)
          .update({
            last_edited: now,
          });
        var files = [];
        var data = {};
        var tasks = {};
        var columns = {};
        var columnOrder = [];
        var _ids = [];

        pending_ref.orderBy("index", "asc").onSnapshot(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            files.push(doc.data().filename);
            tasks[doc.id] = {
              id: doc.id,
              content: doc.data().filename,
              compressed_filename: doc.data().compressed_filename,
              url: doc.data().url,
              trackName: doc.data().track_name,
              index: 0,
            };
            _ids.push(doc.id);
          });
          columns["column-2"] = {
            id: "column-2",
            // title: album.name,
            title: "/pending",
            taskIds: _ids,
          };

          columnOrder = ["column-2"];
          data["tasks"] = tasks;
          data["columns"] = columns;
          data["columnOrder"] = columnOrder;
          resolve(data);
        });
      } else {
        ////("signed out....");

        reject("error signed out");
      }
    });
  });
}

async function getRecentDrafts() {
  // var type = "album"
  const response = await getMostRecentAlbumDraft();
  ////(response);
  return response;
}
async function getLatest(type = "album") {
  const uid = localStorage.getItem("uid");

  const snapshot = await db
    .collection("studio")
    .doc(uid)
    .collection("drafts")
    .get();

  return snapshot.docs.map((doc) => doc.data());
}
async function getMostRecentAlbumDraft() {
  return new Promise((resolve, reject) => {
    const uid = localStorage.getItem("uid");
    db.collection("studio")
      .doc(uid)
      .collection("drafts")
      .orderBy("last_edited", "desc")
      .limit(1)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          var album = {
            name: doc.data().name,
            docId: doc.id,
          };
          resolve(album);
        });
      })
      .catch(function (error) {
        ////("Error getting documents: ", error);
      });
  });
}

async function initializeAlbum(name) {
  return new Promise((resolve, reject) => {
    const uid = localStorage.getItem("uid")
    // console.log("the uid: ", uid);
    var drafts = db.collection("studio").doc(uid).collection("drafts");

    var initDraftAlbumRef = drafts
      .add({
        name: name,
      })
      .then((docRef) => {
        ////("Document written with ID: ", docRef.id);
        var now = firebase.firestore.Timestamp.now();
        drafts.doc(docRef.id).set({
          last_edited: now,
          name: name,
        });
        ////("id: ", docRef.id);
        var album = {
          name: name,
          docId: docRef.id,
        };
        resolve(album);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        reject(error);
      });
  });
}

async function initializeOtariProject(name) {
  return new Promise((resolve, reject) => {
    const uid = localStorage.getItem("uid");
    var drafts = db.collection("studio").doc(uid).collection("services").doc("analog").collection("otari");

    var initDraftAlbumRef = drafts
      .add({
        name: name,
      })
      .then((docRef) => {
        ////("Document written with ID: ", docRef.id);
        var now = firebase.firestore.Timestamp.now();
        drafts.doc(docRef.id).set({
          last_edited: now,
          name: name,
        });
        ////("id: ", docRef.id);
        var album = {
          name: name,
          docId: docRef.id,
        };
        resolve(album);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        reject(error);
      });
  });
}

async function getTracksForAlbum(album) {
  return new Promise((resolve, reject) => {
    const uid = localStorage.getItem("uid");
    localStorage.setItem("uid", uid);
    ////("the docid: ", uid, "  ", album.docId);
    var album_tracks_ref = db
      .collection("studio")
      .doc(uid)
      .collection("drafts")
      .doc(album.docId)
      .collection("tracks");

    var files = [];
    var data = {};
    var tasks = {};
    var columns = {};
    var columnOrder = [];
    var _ids = [];

    album_tracks_ref.onSnapshot(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        files.push(doc.data().filename);
        tasks[doc.id] = {
          id: doc.id,
          content: doc.data().filename,
          compressed_filename: doc.data().compressed_filename,
          url: doc.data().url,
          trackName: doc.data().track_name,
        };
        _ids.push(doc.id);
      });
      columns["column-2"] = {
        id: "column-2",
        title: "/drafts/albums/" + album.name + ".albm",
        taskIds: _ids,
      };
      columnOrder = ["column-2"];
      data["tasks"] = tasks;
      data["columns"] = columns;
      data["columnOrder"] = columnOrder;
      ////("the daterr", data);
      resolve(data);
    });
  });
}


async function getRegularAlbumTracks(docId) {
  return new Promise((resolve, reject) => {
    const uid = localStorage.getItem("uid");
    localStorage.setItem("uid", uid);
    ////("the docid: ", uid, "  ", album.docId);
    var album_tracks_ref = db
      .collection("studio")
      .doc(uid)
      .collection("drafts")
      .doc(docId)
      .collection("tracks");

    var tracks = [];

    album_tracks_ref.onSnapshot(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        var track = {
          compressed_filename: doc.data().compressed_filename,
          filename: doc.data().filename,
          index: doc.data().index,
          last_updated: doc.data().last_updated,
          track_name: doc.data().track_name,
          url: doc.data().url,
        }
        tracks.push(track)
      });

      resolve(tracks);
    });
  
  });
}

async function getFiles() {
  const response = await getFBData();
  return response;
}

async function getAlbumDraft(album) {
  const response = await getADData(album);
  return response;
}


async function getOtariProject(project) {
  const response = await getOtariProjectData(project);
  return response;
}

async function getJustFiles(albumName) {
  const response = await getJustFilesData(albumName);
  return response;
}

async function getMXT() {
  const response = await getMXTData();
  ////(response);
  return response;
}

const fetchData = async (uid) => {
  var files_ref = db.collection("studio").doc(uid).collection("files");
  files_ref.onSnapshot(function (querySnapshot) {
    var files = [];
    querySnapshot.forEach(function (doc) {
      files.push(doc.data().filename);
    });
    return files;
  });
};

async function setFiles() {
  const response = await setFBData();
  return response;
}

async function setMXTFiles(task) {
  var filename = task.content;
  var fileURL = task.url;
  setMXTData(filename, fileURL);
}

async function setAlbumTrack(task, album) {
  var filename = task.content;
  var compressed_filename = task.compressed_filename;
  var fileURL = task.url;
  var index = task.index;
  var trackName = task.trackName;
  if (trackName == null) {
    trackName = "";
  }
  var lastUpdated = firebase.firestore.Timestamp.now();

  return setAlbumTrackData(
    (filename = filename),
    (compressed_filename = compressed_filename),
    (fileURL = fileURL),
    (album = album),
    (trackName = trackName),
    (index = index),
    (lastUpdated = lastUpdated)
  );
}

async function setAlbumName(name, album) {
  try {
      const response = await setAlbumNameData(name, album);
      console.log(response);
  } catch (error) {
      console.error("Error setting album name: ", error);
  }
}


function updateAlbumIndices(album) {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      var uid = user.uid;
      var batch = db.batch();
      var lastUpdated = firebase.firestore.Timestamp.now();
      album.songs.map((song, index) => {
        var song_ref = db.doc(
          `studio/${uid}/drafts/${album.docId}/tracks/${song.id}`
        );
        batch.update(song_ref, { index: index });
      });
      batch.commit().then(() => {
        // ...
      });
    }
  });
}
// creates new tracks
function setAlbumNameData(name, album) {
  return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      if (user) {
          const uid = user.uid;
          const draftAlbumTracksRef = db.collection("studio").doc(uid).collection("drafts").doc(album.docId);

          const lastUpdated = firebase.firestore.Timestamp.now();

          draftAlbumTracksRef.update({
              name: name,
              last_updated: lastUpdated,
          })
          .then(() => {
              resolve("Album name updated successfully.");
          })
          .catch((error) => {
              console.error("Error updating album name: ", error);
              reject(error);
          });
      } else {
          reject("User is not signed in.");
      }
  });
}


async function updateAlbumDraftTrack(task, album, index) {
  ////("task: ", task);
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //         Fv4iExfoNLRLc8Qu2kum:
        // content: "Artboard Copy.png"
        // id: "Fv4iExfoNLRLc8Qu2kum"
        // url: "https://firebasestorage.googleapis.com/v0/b/tua
        var uid = user.uid;
        var files_ref = db.collection("studio").doc(uid).collection("files");
        var now = firebase.firestore.Timestamp.now();

        var draft_album_tracks_ref = db
          .collection("studio")
          .doc(uid)
          .collection("drafts")
          .doc(album.docId)
          .collection("tracks")
          .doc(task.id);

        draft_album_tracks_ref
          .update({
            track_name: task.trackName,
            last_updated: now,
          })
          .then(function (docRef) {
            ////("Document written with ID: ", docRef.id);
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });

        var data = {};
        resolve(data);
        // });
      } else {
        ////("signed out....");
        reject("error signed out");
      }
    });
  });
}
async function getDrafts() {
  var albums = [];
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var drafts_ref = db
          .collection(`studio/${uid}/drafts`)
          .orderBy("last_edited", "desc")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().is_published != true) {
                var _album = {
                  name: doc.data().name,
                  docId: doc.id,
                };
                albums.push(_album);
              }
            });
            resolve(albums);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
            reject(error);
          });
      }
    });
  });
}


// get tracks for a draft album
// get draft async function
async function getDraft(docId) {
  var album = { docId: docId };
  const tracks = await getRegularAlbumTracks(docId);
  console.log("tracks: ", tracks);
  const uid = localStorage.getItem("uid");
  return new Promise((resolve, reject) => {
    var drafts_ref = db
      .collection(`studio/${uid}/drafts`)
      .doc(docId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          album = {
            name: doc.data().name,
            docId: doc.id,
            published: doc.data().published,
            last_edited: doc.data().last_edited,
            last_updated: doc.data().last_updated,
            tracks: tracks,
          };
          resolve(album);
        } else {
          console.log("No such document!");
          reject("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        reject(error);
      });

  });
}




// 



async function getProjects() {
  var albums = [];
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var drafts_ref = db
          .collection(`studio/${uid}/services/analog/otari`)
          .orderBy("last_edited", "desc")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().is_published != true) {
                var _album = {
                  name: doc.data().name,
                  docId: doc.id,
                };
                albums.push(_album);
              }
            });
            resolve(albums);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
            reject(error);
          });
      }
    });
  });
}

// creates new tracks
function setAlbumTrackData(
  filename,
  compressed_filename,
  fileURL,
  album,
  trackName,
  index,
  lastUpdated
) {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        var files_ref = db.collection("studio").doc(uid).collection("files");
        var draft_album_tracks_ref = db
          .collection("studio")
          .doc(uid)
          .collection("drafts")
          .doc(album.docId)
          .collection("tracks");

        draft_album_tracks_ref
          .add({
            index: index,
            track_name: trackName,
            filename: filename,
            compressed_filename: compressed_filename,
            url: fileURL,
            last_updated: lastUpdated,
          })
          .then(function (docRef) {
            resolve(docRef.id);
            ////("Document written with ID: ", docRef.id);
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });

      } else {
        ////("signed out....");
        reject("error signed out");
      }
    });
  });
}
function setMXTData(filename, fileURL) {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //         Fv4iExfoNLRLc8Qu2kum:
        // content: "Artboard Copy.png"
        // id: "Fv4iExfoNLRLc8Qu2kum"
        // url: "https://firebasestorage.googleapis.com/v0/b/tua
        var uid = user.uid;
        var files_ref = db.collection("studio").doc(uid).collection("files");

        var mxt_tracks_ref = db
          .collection("studio")
          .doc(uid)
          .collection("mxt")
          .doc("default")
          .collection("tracks");
        mxt_tracks_ref
          .add({
            filename: filename,
            url: fileURL,
          })
          .then(function (docRef) {
            ////("Document written with ID: ", docRef.id);
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
        // mxt_ref.

        // db.collection("cities")
        //   .doc("LA")
        //   .set({
        //     name: "Los Angeles",
        //     state: "CA",
        //     country: "USA",
        //   })
        //   .then(function () {
        //     ////("Document successfully written!");
        //   })
        //   .catch(function (error) {
        //     console.error("Error writing document: ", error);
        //   });

        files_ref.onSnapshot(function (querySnapshot) {
          var files = [];
          var data = {};
          var tasks = {};
          var columns = {};
          var columnOrder = [];
          var _ids = [];
          querySnapshot.forEach(function (doc) {
            files.push(doc.data().filename);
            tasks[doc.id] = {
              id: doc.id,
              content: doc.data().filename,
              url: doc.data().url,
            };
            _ids.push(doc.id);
          });
          columns["column-1"] = {
            id: "column-1",
            title: "/files",
            taskIds: _ids,
          };
          columns["column-2"] = {
            id: "column-2",
            title: "/.mixtape",
            taskIds: [],
          };

          columnOrder = ["column-1", "column-2"];
          data["tasks"] = tasks;
          data["columns"] = columns;
          data["columnOrder"] = columnOrder;

          resolve(data);
        });
      } else {
        ////("signed out....");
        reject("error signed out");
      }
    });
  });
}

function setFBData() {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //         Fv4iExfoNLRLc8Qu2kum:
        // content: "Artboard Copy.png"
        // id: "Fv4iExfoNLRLc8Qu2kum"
        // url: "https://firebasestorage.googleapis.com/v0/b/tua
        var uid = user.uid;
        var files_ref = db.collection("studio").doc(uid).collection("files");

        files_ref.onSnapshot(function (querySnapshot) {
          var files = [];
          var data = {};
          var tasks = {};
          var columns = {};
          var columnOrder = [];
          var _ids = [];
          querySnapshot.forEach(function (doc) {
            files.push(doc.data().filename);
            tasks[doc.id] = {
              id: doc.id,
              content: doc.data().filename,
              url: doc.data().url,
            };
            _ids.push(doc.id);
          });
          columns["column-1"] = {
            id: "column-1",
            title: "/files",
            taskIds: _ids,
          };
          columns["column-2"] = {
            id: "column-2",
            title: "/.mixtape",
            taskIds: [],
          };

          columnOrder = ["column-1", "column-2"];
          data["tasks"] = tasks;
          data["columns"] = columns;
          data["columnOrder"] = columnOrder;

          resolve(data);
        });
      } else {
        ////("signed out....");
        reject("error signed out");
      }
    });
  });
}

async function validateDraft(album) {
  var promise = new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        let albumCoverQuery = db
          .collection(`studio/${uid}/drafts/${album.docId}/images/`)
          .orderBy("last_updated", "desc")
          .limit(1);
        let albumTracksQuery = db
          .collection(`studio/${uid}/drafts/${album.docId}/tracks/`)
          .orderBy("index", "desc");

        var _album = {
          name: album.name,
        };
        var tracks = [];

        albumCoverQuery
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.empty) {
              alert("no image uploaded, please upload an album cover");
              reject("no image");
            } else {
              querySnapshot.forEach(function (doc) {
                var image = {
                  url: doc.data().public_url,
                  docId: doc.id,
                };
                _album.image_url = image.url;
              });
              albumTracksQuery
                .get()
                .then(function (querySnapshot) {
                  if (querySnapshot.empty) {
                    alert("add tracks before publishing");
                    reject("no tracks");
                  } else {
                    var validTracks = true;
                    querySnapshot.forEach(function (doc) {
                      let _name = doc.data().track_name;
                      if (_name != "") {
                        var track = {
                          name: _name,
                          docId: doc.id,
                        };
                        tracks.push(track);
                      } else {
                        validTracks = false;
                      }
                    });
                    if (!validTracks) {
                      alert(
                        "all tracks must have a title. click edit button to rename tracks"
                      );
                      reject("empty track name");
                    } else {
                      _album.docId = album.docId;
                      _album.tracks = tracks;
                      _album.isValid = true;
                      resolve(_album);
                    }
                  }
                })
                .catch(function (error) {
                  console.log("Error getting documents: ", error);
                });
            }
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      } else {
        ////("signed out....");
        reject("error signed out");
      }
    });
  });

  return promise;
}

function getTimeStamp() {
  var now = firebase.firestore.Timestamp.now();

  return now;
}
async function updateAlbumCover(album, downloadURL, filename, file) {
  // const compressed_file = await utils.compressImage(file)

  var promise = new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;

        let albumCover = db
          .collection(`studio/${uid}/drafts/${album.docId}/images/`)
          .doc();
        albumCover.set({
          last_updated: getTimeStamp(),
          public_url: downloadURL,
          filename: filename,
        });

        resolve(downloadURL);
      } else {
        ////("signed out....");
        reject("error signed out");
      }
    });
  });

  return promise;
}
function getAlbumDraftCover(album) {

  var promise = new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        let albumCoverQuery = db
          .collection(`studio/${uid}/drafts/${album.docId}/images/`)
          .orderBy("last_updated", "desc")
          .limit(1)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.empty) {
              resolve("no image");
            } else {
              querySnapshot.forEach(function (doc) {
                var image = {
                  url: doc.data().public_url,
                  docId: doc.id,
                };

                resolve(image);
              });
            }
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      }
    });
  });
  return promise;
}

const updateTrackAndAlbumIndices = async (track, album, newTracks) => {
  try {
    var trackName = track.trackName;
    if (trackName == null) {
      trackName = "";
    }
    // create an optionally blank filename

    var filename = track.content;
    if (filename == null) {
      filename = "";
    }
    var compressed_filename = track.compressed_filename;
    if (compressed_filename == null) {
      compressed_filename = "";
    }
    var fileURL = track.url;
    if (fileURL == null) {
      fileURL = "";
    }

    var index = track.index;
    var lastUpdated = firebase.firestore.Timestamp.now();



    const newTrackId = await setAlbumTrackData(
      filename,
      compressed_filename,
      fileURL,
      album,
      trackName,
      index,
      lastUpdated
    );


    track.id = newTrackId;
    const updatedAlbumWithNewTrack = { ...album, songs: newTracks };

    updateAlbumIndices(updatedAlbumWithNewTrack);
    return newTrackId;
  } catch (error) {
    console.error("Error updating track and album indices: ", error);
    throw error;
  }
};

const getNewTrackId = async (album, track) => {
  firebase.auth().onAuthStateChanged((user) => {

    if (user) {
      var uid = user.uid;
      var draft_album_tracks_ref = db
        .collection("studio")
        .doc(uid)
        .collection("drafts")
        .doc(album.docId)
        .collection("tracks");

      draft_album_tracks_ref
        .add({
          index: track.index,
          track_name: track.trackName,
          filename: track.content,
          compressed_filename: track.compressed_filename,
          url: track.url,
          last_updated: firebase.firestore.Timestamp.now(),
        })
        .then(function (docRef) {
          return docRef.id;
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
  });
}
const updateDraftOpenedAt = (docId) => {
  const user = getCurrentUser();
  if (!user) {
    console.error("No user logged in");
    return;
  }

  const uid = user.uid;
  const draftRef = db.collection("studio").doc(uid).collection("drafts").doc(docId);
  
  return draftRef.update({
    opened_at: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

const storage = {
  updateDraftOpenedAt,
  deleteElement,
  getFiles,
  getJustFiles,
  setFiles,
  getMXT,
  getMXTData,
  getFBData,
  setMXTData,
  setMXTFiles,
  getAlbumDraft,
  setAlbumTrack,
  setAlbumTrackData,
  setAlbumName,
  setAlbumNameData,
  fetchData,
  setStudioData,
  getStudioData,
  myTapesObserver,
  makeRequest,
  updateAlbumDraftTrack,
  initializeAlbum,
  getRecentDrafts,
  getLatest,
  getTracksForAlbum,
  updateAlbumIndices,
  getDrafts,
  deleteAlbum,
  updateAlbumCover,
  getAlbumDraftCover,
  validateDraft,
  getOtariProject,
  getOtariProjectData,
  getProjects,
  initializeOtariProject,
  getShareableLink,
  getDraft,
  updateTrackAndAlbumIndices,
  getNewTrackId,

};

export default storage;
