import styled from "styled-components";

const SettingsContainer = styled.div`
  text-align: center;
  /* display: flex; */
  .container {
    display: flex;
    /* or inline-flex */
    flex-basis: auto; /* default auto */
  }

  .grid {
    /* height: 5vh; */
    /* border: 2px solid blue; */
    display: flex;

    align-items: center;
    /* Default */
    justify-content: space-between;
  }
  .title {
    display: flex;

    align-items: center;
    /* Default */
    justify-content: space-between;
    /* width: 100%; */
    /* background: #cacaca; */
    border: 3px solid green;
    color: green;
    height: 5vh;
    padding: 4vh;
  }
  .box {
      padding: 2vh;
  }
  li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    /* background: #fafafa; */
    padding: 20px;
  }
  .item {
    order: 5; /* default is 0 */
  }
  .subscribe {
    display: flex;
    /* height: 50px; */
    padding: 20px;
  }
`;
export default SettingsContainer;
