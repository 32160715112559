// SmallLogoView.jsx;
import React, { useContext } from "react";
import logo from "../../assets/mxt_logo_extra_small.png"; // Tell webpack this JS file uses this image
import LogoContainer from "../themes/LogoContainer";
// import phaze_logo from "../../assets/phaze-svg-1.svg";
import phaze_logo from "../../assets/phaze-iib.svg";
import APIConfig from "../../APIConfig";
//(logo); // /logo.84287d09.png

const SmallLogoView = () => {
  // Import result is the URL of your image
  var logo = "https://storage.googleapis.com/tuareg/media/mxt-logo.svg"
  var airtrak_logo = "https://firebasestorage.googleapis.com/v0/b/tuareg.appspot.com/o/app_assets%2Fimages%2Fairtrak-logo.svg?alt=media&token=4bec72f2-a13d-47a8-a27d-5ba10b5b10a9"
  if (APIConfig.phaze() === true) {
    logo = phaze_logo
  }
  if (APIConfig.airtrak() === true) {
    logo = airtrak_logo
  }
  
  // var logo = phaze_logo;
  return (
    // <LogoContainer className="box">
    <div>
      <img
        style={{ height: 45, padding: "0.3em" }}
        src={logo}
        className="selected"
        alt="Logo"
      />
    </div>
    // </LogoContainer>
  );
};

export default SmallLogoView;
