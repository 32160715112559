import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { firebaseAuth } from "../../provider/AuthProvider";
import { useAuthState } from "react-firebase-hooks/auth";
import { Title } from "./Title";
import WideLinkButton from "../buttons/WideLinkButton";
import firebase from "firebase/compat/app";
import SmallLogoView from "../aesthetic/SmallLogoView";
import SearchBar from "./SearchBar";
import MenuButton from "./MenuButton";
import MenuItems from "./MenuItems";
import SmallBetaDownloadButtonView from "../landing/SmallBetaDownloadButtonView";

const NavBar = () => {
  const { token } = useContext(firebaseAuth);
  const location = useLocation();
  const auth = firebase.auth();
  const [user] = useAuthState(auth);
  const [isOpen, setIsOpen] = useState(false);
  const isRoot = location.pathname === "/";

  let altAuthPath;
  if (location.pathname === "/signin") {
    // altAuthPath = { path: "/signup", text: "sign up" };
  } else if (location.pathname === "/signup") {
    altAuthPath = { path: "/signin", text: "sign in" };
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="pb-5">
      <nav className="fixed top-0 w-full z-10 bg-gh_charcoal-100 dark:bg-gh_charcoal_secondary-100 shadow-md">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div className="flex space-x-4">
              <Title />
              {/* Other components */}
            </div>
            <div className="flex items-center space-x-4">
              <SmallBetaDownloadButtonView />
              <MenuItems
                user={user}
                altAuthPath={altAuthPath}
                isRoot={isRoot}
                isOpen={isOpen}
              />
              <MenuButton toggleMenu={toggleMenu} />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
