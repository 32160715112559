// EventDetailsView.jsx
import EventLinkButton from "./EventLinkButton";
import EventTimeView from "./EventTimeView";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { useEffect } from "react";
// import AddToCalendarButton from "./calendar/AddToCalendarButton";
import React, { useMemo } from "react";

import FollowButton from "./follows/FollowButton";
import EventAddressView from "./EventAddressView";
import EventDate from "./calendar/EventDate";
import EventDescriptionView from "./EventDescriptionView";
import HostedByView from "./HostedByView";
import ArtistSetTimesView from "./artists/ArtistSetTimesView";
import AddToCalendarCustomButton from "./AddToCalendarCustomButton";
const EventDetailsView = ({
  event,
  linkCopied,
  presentEventCopySuccessToast,
  loadAttendingView,
  isAttending,
  eventTime,
  address,
}) => {
  const renderAddToCalendarButton = () => {
    // if (!formattedTime) return null;

    return (
      <div className="m-2">
        <AddToCalendarCustomButton event={event} />
      </div>
    );
  };

  useEffect(() => {
    console.log("EventDetailsView mounted.");
    console.log("EventDetailsView props:", event);
    return () => {
      console.log("EventDetailsView unmounted.");
    };
  }, [event]);

  return (
    <div className="p-2 py-1 ">
      <div className="py-2">
        <HostedByView event={event} className="" />
        <EventDate date={event.date} />
        <EventTimeView time={event.time} />
        <div className="-m-2">{renderAddToCalendarButton()}</div>
      </div>
      <div className="py-2">
        <EventLinkButton
          event={event}
          linkCopied={linkCopied}
          presentEventCopySuccessToast={presentEventCopySuccessToast}
        />
      </div>
      <EventAddressView event={event} isRSVPed={isAttending} />
      <EventDescriptionView description={event.description} />
      {event.id === 88 && <ArtistSetTimesView eventId={event.id} />}
      <div className="py-2">{loadAttendingView()}</div>
    </div>
  );
};

export default EventDetailsView;
