import React, { useState, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PencilIcon } from "@heroicons/react/24/outline";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TrackCellArtistView from "./TrackCellArtistView";
import ArtistSearch from "../../search/ArtistSearch";
import useArtistChange from "./album_creator/hooks/useArtistChange";

// const MemoizedTrackCellArtistView = memo(TrackCellArtistView);

const TrackArtistEditor = ({ track, album }) => {
  const [editMode, setEditMode] = useState(false);
  const { handleArtistChange } = useArtistChange(track, album);

  return (
    <div className="cursor-pointer">
      {editMode ? (
        <div className="flex items-start justify-start py-5 mb-5">
          <div className="flex-grow pr-2 relative">
            <ArtistSearch
              onSelect={(artist) => {
                handleArtistChange(artist);
                setEditMode(false);
              }}
              renderResult={(artist) => (
                <li className="py-2 hover:bg-gray-600">{artist.name}</li>
              )}
              placeholder="Search and select artist..."
              styleVariant="compact"
            />
            <div className="absolute right-0 top-0 p-2">
              <button
                onClick={() => setEditMode(false)}
                className="text-red-500 hover:text-red-600"
              >
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => setEditMode(true)}
          className="flex items-center justify-start"
        >
          <TrackCellArtistView  artistId={track.metadata.artist_id} />
          <div className="hover:bg-gh_charcoal-100 p-2 rounded">
            <PencilIcon className="h-4 w-4 text-gray-500 cursor-pointer " />
          </div>
        </div>
      )}
    </div>
  );
};

export default TrackArtistEditor;
