import React from 'react';

const PhazeSplashPage = () => {
  return (
    <div className="w-full h-screen bg-black px-6 sm:px-12 lg:px-24 py-12 text-white flex items-center justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-7xl gap-8">
        <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left space-y-6 md:space-y-8">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tighter" style={{ fontFamily: '"Test Söhne Mono", monospace' }}>
            PHAZE *
          </h2>
        </div>
        <div className="flex items-center text-center md:text-left">
          <p className="text-sm sm:text-base font-light opacity-70 leading-relaxed w-full sm:max-w-md" style={{ fontFamily: '"Test Söhne Mono", monospace' }}>
            * Here's to nights out that turn into stories, friends who stay out too late, <a href="https://www.amazon.com/ROYAL-GIRL-Vintage-Sunglasses-Designer/dp/B07C1LWSL3/ref=asc_df_B07C1LWSL3/?tag=hyprod-20&linkCode=df0&hvadid=309955763012&hvpos=&hvnetw=g&hvrand=7511201046225330897&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9067609&hvtargid=pla-570266677144&mcid=ba2205076da5327eb26b631548e9ab24&gclid=CjwKCAiAhJWsBhAaEiwAmrNyq_a-Pa36K3IMQV0s-uwKdKx4pxrwxCqwe-omKui5x2b7BgJziAK4ORoCNE4QAvD_BwE&th=1" target="_blank" rel="noopener noreferrer" style={{ color: '#d81b60' }}>tiny sunglasses</a>, and DJs that don't take requests—we salute you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PhazeSplashPage;
