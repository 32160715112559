// OverlayedEventBannerImage.jsx

import React, { useState, useEffect } from "react";
import { EventDetailService } from "../file_mgmt/drive/hooks/loadBannerImage";
import ImageViewer from "./memories/ImageViewer";

const OverlayedEventBannerImage = ({ eventId, eventName }) => {
  const [bannerImage, setBannerImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);

  useEffect(() => {
    const loadBannerImage = async () => {
      console.log("Attempting to load banner image for event ID:", eventId);
      try {
        const bannerImageUrl = await EventDetailService.loadBannerImage(eventId);
        console.log("Banner Image URL retrieved:", bannerImageUrl);
        setBannerImage(bannerImageUrl);
      } catch (error) {
        console.error("Error loading banner image:", error);
      }
    };

    loadBannerImage();
  }, [eventId]);

  const openImageViewer = () => {
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsImageViewerOpen(false);
  };

  const handleImageLoad = () => {
    console.log("Image has loaded.");
    setIsLoading(false);
  };

  const handleImageError = () => {
    console.error("Error loading the image.");
    setIsLoading(false);
  };

  return (
    <>
      <div className="w-full h-56 sm:h-96 lg:h-auto flex items-center justify-center overflow-hidden m-auto relative">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="loader"></div> {/* Centered loading indicator */}
          </div>
        )}
        <button onClick={openImageViewer} className="w-full h-full flex items-center justify-center bg-transparent border-none">
          <img
            src={bannerImage}
            alt="Event Banner"
            onLoad={handleImageLoad}
            onError={handleImageError}
            className={`object-cover w-full h-auto ${isLoading ? 'hidden' : ''}`}
          />
          {!isLoading && (
            <div className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-black to-transparent flex items-end">
              <span className="text-white sm:text-6xl text-2xl font-scopeMonoBoldExpanded sm:py-5 py-2 sm:px-2 px-1 m-4">{eventName}</span>
            </div>
          )}
        </button>
      </div>

      <ImageViewer isOpen={isImageViewerOpen} onRequestClose={closeImageViewer} imageUrl={bannerImage} />
    </>
  );
};

export default OverlayedEventBannerImage;
