import React from "react";
import useFetchAlbumsData from "../../events/hooks/useFetchAlbumsData";
import AlbumsSkeletonGrid from "../AlbumsSkeletonGrid";

const AlbumsComponent = () => {
  const { albumsData, loading } = useFetchAlbumsData();

  return (
    <div>
      {loading ? (
        <AlbumsSkeletonGrid />
      ) : (
        <div className="grid grid-cols-3 gap-4 p-4">
          {" "}
          {albumsData.results.map((album) => (
            <div
              key={album.id}
              className="border rounded shadow-lg overflow-hidden"
            >
              <img
                src={album.compressed_artwork}
                alt={album.name}
                className="w-full h-auto"
              />
              <div className="p-2">
                <h2 className="text-lg font-semibold">{album.name}</h2>
                {/* Include other album details if needed */}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AlbumsComponent;
