// EventAddressView.jsx
import React from "react";
import { MapPinIcon } from "@heroicons/react/24/solid";

const EventAddressView = ({ event, isRSVPed }) => {
  if (isRSVPed) {
    return (
      <div className="flex flex-row">
        <div className="py-1">
          <MapPinIcon className="h-5 w-5  " />
        </div>
        <div className="px-2 font-theFutureMonoRegular">
          {event.venue ? (
            <p className="py-0.5">
              {event.venue.name}
              <br></br>
              {event.venue.address.address_line_1}{" "}
              {event.venue.address.address_line_2} {event.venue.address.city}
              <br></br> {event.venue.address.state}{" "}
              {event.venue.address.zip_code}
            </p>
          ) : (
            <p className="py-0.5  ">
              {" "}
              dm for address
            </p>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-row">
        <div className="font-theFutureMonoRegular">
          <p className="py-0.5  ">
            RSVP to see address
          </p>
        </div>
      </div>
    );
  }
};

export default EventAddressView;
