// Title.jsx
import React from "react";
import { NavLink } from "react-router-dom";
import APIConfig from "../../APIConfig";
import SmallLogoView from "../aesthetic/SmallLogoView";
export const Title = () => {
  const text = APIConfig.phaze() ? "phaze" : "airtrak";
  const titleClasses = APIConfig.phaze()
    ? "py-2 text-lg font-picnic text-transparent bg-clip-text bg-gradient-to-t from-green-300 via-blue-500 to-purple-600"
    : "py-2 px-1 font-theFutureMonoRegular";

  return (
    // <div className="grid grid-cols-2 gap-3 content-end">
    <div>
      <NavLink className="" activeClassName="is-active" to="/">
        {/* <div className="flex flex-row"> */}
          <SmallLogoView />
          {/* <h1 className={titleClasses}>{text}</h1> */}
        {/* </div> */}
      </NavLink>
    </div>
  );
};
