// EventTimeView.jsx
import React from 'react';
import { ClockIcon } from '@heroicons/react/24/solid';

const EventTimeView = ({ time }) => {
  const getTime = (timeString) => {
    var time = new Date('1/1/2021 ' + timeString);
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };

  return (
    <div className="px-1 flex flex-row">
      <div className="py-3">
        <ClockIcon className="h-4 w-4  dark:" />
      </div>
      <div className="px-3">
        <p className="font-theFutureMonoRegular ">
          <div className=" py-2 font-theFutureMonoRegular">{getTime(time)}</div>
        </p>
      </div>
    </div>
  );
};

export default EventTimeView;
