// configureStore.js


import {applyMiddleware, createStore, compose} from 'redux'
import rootReducer from '../reducers/rootReducer'
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const composeEnhancers =
  typeof window === "object" &&
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose);

// require('babel-core/register')

export default function configureStore(defaultState = {}) {

  const store = createStore(rootReducer, defaultState, composeWithDevTools(applyMiddleware(thunk)))

  return store;
}



