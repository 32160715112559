// UserDisplay.jsx
import React from 'react';
import AvatarView from "../AvatarView"; // Update the path as necessary

const UserDisplay = ({ username, profilePic }) => (
  <div className="mt-2 mb-2"> {/* Adjust margin as necessary */}
    {/* <AvatarView
      username={username}
      profilePic={profilePic}
      showUsername={false} // Assuming AvatarView should not show the username, it's handled here
    /> */}
    <p className="text-xs font-mono text-gray-800 dark:text-gray-200">@{username}</p>
  </div>
);

export default UserDisplay;
