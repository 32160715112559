// AttendingView.jsx
import React, { useEffect, useState } from "react";
import AttendingModalAttendeeListView from "./AttendingModalAttendeeListView";
const AttendingView = ({ event }) => {
  const [showModal, setShowModal] = useState(false);

  // Determine modal width based on attendee count
  const getModalWidthClass = (count) => {
    if (count <= 5) return 'max-w-md';     // Small modal for few users
    if (count <= 10) return 'max-w-lg';    // Medium modal for a moderate number of users
    if (count <= 20) return 'max-w-2xl';   // Large modal for many users
    return 'max-w-3xl';                    // Extra large modal for a very large number of users
  };

  const modalWidthClass = getModalWidthClass(event.attendee_count);

  useEffect(() => {
    console.log("event in attending view: ", event);
  }, [event]);

  return (
    <div>
      <AttendingModalTriggerButton
        count={event.attendee_count}
        onTrigger={() => setShowModal(true)}
      />
      <AttendingModalView showModal={showModal} setShowModal={setShowModal} widthClass={modalWidthClass}>
        <AttendingModalHeaderView
          title="attending"
          onClose={() => setShowModal(false)}
        />
        <AttendingModalAttendeeListView event={event} />
      </AttendingModalView>
    </div>
  );
};

// export default AttendingView;

// AttendingModalView.jsx
const AttendingModalView = ({ showModal, setShowModal, children, widthClass }) => {
  if (!showModal) return null;

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 dark:bg-black dark:bg-opacity-30 flex items-center justify-center z-50 outline-none overflow-y-auto">
      <div className={`bg-white dark:bg-gray-800 ${widthClass} mx-auto border border-gray-200 dark:border-gray-700 p-4 overflow-auto`}>
        {children}
      </div>
    </div>
  );
};
// AttendingModalHeaderView.jsx
const AttendingModalHeaderView = ({ title, onClose }) => (
  <div className="flex justify-between items-center px-5 py-4 border-b border-gray-200 dark:border-gray-700">
    <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
      {title}
    </h2>
    <AttendingModalCloseButton onClose={onClose} />
  </div>
);

// AttendingModalCloseButton.jsx
const AttendingModalCloseButton = ({ onClose }) => (
  <button
    onClick={onClose}
    className="p-1 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-full focus:outline-none"
  >
    <svg
      className="h-6 w-6 text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
);


// AttendingModalTriggerButton.jsx
const AttendingModalTriggerButton = ({ count, onTrigger }) => (
  <button
    className="text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-100"
    onClick={onTrigger}
  >
    <p className="py-0.5 font-theFutureMonoRegular underline text-blue-400 dark:text-blue-300">
      {count} attending
    </p>
  </button>
);

export default AttendingView;
