import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyDhMcB5RthdMyRvDL1wceYKzedX4pot71Q",
  authDomain: "tuareg.firebaseapp.com",
  databaseURL: "https://tuareg.firebaseio.com",
  projectId: "tuareg",
  storageBucket: "tuareg.appspot.com",
  messagingSenderId: "374643357531",
  appId: "1:374643357531:web:faffb186d032ebf0c6cc7e",
};

firebase.initializeApp(firebaseConfig);
firebase.auth();

// Use ReCaptchaEnterpriseProvider for enterprise-grade ReCaptcha
const appCheck = firebase.appCheck();
appCheck.activate(
  new firebase.appCheck.ReCaptchaEnterpriseProvider('6LdJlV4oAAAAAAxgc_EMYzIEggPzqODZjbnCzmx6'),
  true // Set to true to allow auto-refresh
);

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});

firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    if (err.code === "failed-precondition") {
      // Handle multiple tabs open
    } else if (err.code === "unimplemented") {
      // Handle lack of browser support
    }
  });

export default {
  firebaseConfig,
};
