// AccountView.js;
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";


import StripeSampleFooter from "./StripeSampleFooter";
import Product from "./Product";
import PriceChangeForm from "./PriceChangeForm";
import APIConfig from "../../APIConfig.js";

const products = [
  {
    key: 0,
    price: "$5.00",
    name: "studio",
    interval: "month",
    billed: "monthly",
  },
  {
    key: 1,
    price: "$15.00",
    name: "pro studio",
    interval: "month",
    billed: "monthly",
  },
];

function AccountView({ location }) {
  //   //(location);
  var cus_info = JSON.parse(localStorage.getItem("customer_info"));
  var seller_info = localStorage.getItem("seller_info");
  // var subs  = {}
  // if (cus_info.subscriptions ) {
  var subs = { subscription: cus_info.subscriptions.data[0] };
  // }
  // //("info, ", cus_info["invoice_settings"]["default_payment_method"]);
  //("subs, ", subs);
  const customerInfo = useState(cus_info);
  const [accountInformation] = useState(subs);
  let [customerPaymentMethod, setCustomerPaymentmethod] = useState(null);
  let [showChangePriceForm, setShowChangePriceForm] = useState(false);
  let [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
  let [newProductSelected, setNewProdctSelected] = useState("");
  let [selectedProducted, setSelectedProduct] = useState(
    // accountInformation.subscription.plan.nickname
    "studio+"
  );

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await fetch(
        `${APIConfig.payments()}retrieve-customer-payment-method/`,
        {
          method: "post",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodId:
              cus_info["invoice_settings"]["default_payment_method"],
          }),
        }
      );
      const responseBody = await response.json();
      const paymentMethod =
        responseBody.card.brand + " •••• " + responseBody.card.last4;

      setCustomerPaymentmethod(paymentMethod);
    }
    fetchData();
  }, [accountInformation.paymentMethodId]);

  function handleChangePriceForm() {
    setShowChangePriceForm(true);
  }

  function handleClick(key) {
    setNewProdctSelected(products[key].name);
  }

  function cancelSubscription() {

    fetch(`${APIConfig.payments()}cancel-subscription/`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sellerAcctId: seller_info,
        subscriptionId: accountInformation.subscription.id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((cancelSubscriptionResponse) => {
        setSubscriptionCancelled(true);
      });
  }

  function resetDemo() {
    localStorage.clear();
    window.location.href = "/";
  }

  return (
    <div className="p-6">
      {subscriptionCancelled ? (
        <div>
          <div className="flex flex-wrap font-bold text-pasha text-xl mt-6 mb-2">
            subscription canceled
          </div>
          <div>
            <button
              className="bg-pasha hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded"
              type="button"
              onClick={() => resetDemo()}
            >
              return home
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-wrap justify-center mt-4">
            <div className="md:w-2/5 w-full inline-block rounded-md p-4">
              <div
                id="subscription-status-text"
                className="text-center font-bold text-pasha text-2xl"
              >
                account management
              </div>
              <div className="mt-4 border rounded p-4">
                <div className="font-bold text-xl mb-2">account</div>
                <div className="flex justify-between text-gray-600 text-xl">
                  <div>current plan</div>
                  <div className="font-bold text-xl mb-2">
                    {selectedProducted}
                  </div>
                </div>

                <div className="flex justify-between">
                  <div className="text-xl text-gray-600">credit card</div>
                  <span
                    id="credit-card-last-four"
                    className="font-bold text-xl text-gray-600"
                  >
                    {customerPaymentMethod}
                  </span>
                </div>

                <div
                  className="flex justify-between mt-2 mb-2 text-gray-900 font-bold text-xl cursor-pointer"
                  onClick={() => handleChangePriceForm()}
                >
                  {/* <span>
                    change plan <span>→</span>
                  </span> */}
                </div>
                <div
                  className="flex justify-between mt-2 mb-2 text-gray-900 font-bold text-xl cursor-pointer"
                  onClick={() => cancelSubscription()}
                >
                  <span>
                    cancel subscription <span>→</span>
                  </span>
                </div>
              </div>

              {showChangePriceForm ? (
                <div id="prices-form" className="w-full md:mb-8">
                  <div className="text-center text-pasha font-bold text-2xl mt-4 mb-6">
                    change plan
                  </div>
                  <div className="flex justify-between mt-8 mb-8">
                    {products.map((product, index) => {
                      let currentProductSelected = false;
                      if (product.name === selectedProducted) {
                        currentProductSelected = true;
                      }
                      return (
                        <Product
                          key={index}
                          product={product}
                          currentProductSelected={currentProductSelected}
                          handleClick={handleClick}
                        />
                      );
                    })}
                  </div>
                  {newProductSelected ? (
                    <PriceChangeForm
                      customerId={accountInformation.subscription.customer}
                      subscriptionId={accountInformation.subscription.id}
                      currentProductSelected={selectedProducted}
                      newProductSelected={newProductSelected}
                      setShowChangePriceForm={setShowChangePriceForm}
                      setSelectedProduct={setSelectedProduct}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(AccountView);
