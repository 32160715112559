// ProductPage.jsx

// CreateProductView.jsx;
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BsHouseFill } from "react-icons/bs";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import StandardButton from "../../buttons/StandardButton";
import ImageCompressor from "../utilities/ImageCompressor";
import ArtistRegistrationForm from "../../forms/ArtistRegistrationForm";
import EditSubscriptionFlow from "../../forms/publisher/EditSubscriptionFlow";
import ImageUploader from "../../file_mgmt/ImageUploader";
import APIConfig from "../../../APIConfig";
const ProductPage = () => {
 	
  return (
	<div>
		product
	</div>
  );
};

export default ProductPage;
