// SmallBetaDownloadButtonView.jsx
// SmallBetaDownloadButtonView.jsx
import React from "react";
import APIConfig from "../../APIConfig";

const SmallBetaDownloadButtonView = () => {
  const url = APIConfig.testflight();

  const handleClick = () => {
    window.open(url, "_blank");
  };

  return (
    <button
      className="flex items-center bg-white hover:bg-black text-black hover:text-white py-1 px-2 focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
      type="button"
      onClick={handleClick}
      title="Download the app"
    >
      <img
        className="w-4 h-4 mr-2"
        style={{ filter: "invert(1)" }} // This inverts the color to match the button text color
        src="https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg"
        alt="Apple logo"
      />
      <span className="text-xs font-theFutureMonoRegular">App</span>
    </button>
  );
};

export default SmallBetaDownloadButtonView;
