import React from "react";
import { Steps, Step } from "react-step-builder";
import UploadStep from "./UploadStep";
import MetadataStep from "./MetadataStep";
import FinalStep from "./FinalStep";
import styled from "styled-components";
import SetPriceStep from "./SetPriceStep";
import SetNameStep from "./SetNameStep";
import ImageCompressor from "../../artist_dashboard/utilities/ImageCompressor";

const SettingsContainer = styled.div`
  text-align: center;
  /* display: flex; */
  .container {
    display: flex;
    /* or inline-flex */
    flex-basis: auto; /* default auto */
  }
  ul {
    display: flex;

    /* align-items: stretch; */
    /* Default */
    justify-content: space-between;
    /* width: 100%; */
    /* background: #cacaca; */
    border: 2px solid blue;

    margin: 0;
    padding: 0;
  }
  li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    /* background: #fafafa; */
    padding: 20px;
  }
  .item {
    order: 5; /* default is 0 */
  }
`;
const Container = styled.div`
  text-align: center;
  /* display: flex; */
  .container {
    display: flex; /* or inline-flex */
    flex-basis: auto; /* default auto */
  }
  ul {
    display: flex;
    justify-content: space-between;

    /* align-items: stretch; */
    /* Default */
    /* justify-content: space-between; */
    /* width: 100%; */
    /* background: #cacaca; */
    border: 2px solid blue;

    margin: 0;
    padding: 0;
  }
  li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    /* background: #fafafa; */
    padding: 20;
  }
  .item {
    order: 5; /* default is 0 */
  }
  .title {
    padding: 5px;
    margin-left: auto;
  }
  .menu-items {
    margin-right: auto;
    display: flex;
    flex-direction: row;
  }

  .settings {
    /* flex: 0 0 50%; */
    /* display: flex; */
    /* justify-content: flex-end; */
    justify-content: space-between;

    margin: 0;
    padding: 0;
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    /* background: #fafafa; */
    /* padding: 20px; */
  }
`;
function EditSubscriptionFlow() {
  return (
    <div className="p-6">
      <SettingsContainer>
        <ul>
          <Steps>
            <Step component={ImageCompressor} />
            <Step component={SetPriceStep} />
            <Step component={SetNameStep} />
            <Step component={FinalStep} />
          </Steps>
        </ul>
      </SettingsContainer>
    </div>
  );
}

export default EditSubscriptionFlow;
