// StudioPlans.js;
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import TopNavigationBar from "./TopNavigationBar";
import StripeSampleFooter from "./StripeSampleFooter";
import PaymentForm from "./PaymentForm";
import Product from "./Product";
import StudioPaymentForm from "./StudioPaymentForm";
// import "./_index.css";
// 
// import "./stuff.css";
const products = [
  {
    key: 0,
    price: "$4.99",
    name: "studio+",
    interval: "month",
    billed: "monthly",
  },
  // {
  //   key: 1,
  //   price: "$15.00",
  //   name: "pro studio",
  //   interval: "month",
  //   billed: "monthly",
  // },
];

function StudioPlans({ location }) {
  const [productSelected, setProduct] = useState(null);
  const [customer] = useState(location.state.customer);

  function handleClick(key) {
    setProduct(products[key]);
  }

  useEffect(() => {});

  return (
    <div className="p-6">
      <div className="flex flex-wrap justify-center">
        <div className="md:w-1/3 w-full mr-4 md:mb-8">
          <h1 className="text-center font-bold text-3xl mt-4 mb-6">
          try studio+ for free
          </h1>
          <div className="text-center text-xl mt-4 mb-6">
          
          get a free trial and you’ll be able to sell merch with no fees*, effortlessly draft albums with 100gb of cloud storage, and premium mobile features to help you stay on top of your music.
          </div>
          {/* <div className="text-center">
            after your 1-month free trial, .mixtape studio+ is $4.99 per month. credit card required. cancel anytime to stop future charges.
            </div> */}
          <div className="text-center  flex justify-center mb-8">
            {products.map((product, index) => {
              return (
                <Product
                  key={index}
                  product={product}
                  handleClick={handleClick}
                />
              );
            })}
           
           
          </div>
          {productSelected ? (
            <StudioPaymentForm
              productSelected={productSelected}
              customer={customer}
            />
          ) : null}
           <div>
              *payment processing fees will still be charged at 2.9% + 25¢ USD
            </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(StudioPlans);
