import React, { createContext, useState, useEffect } from 'react';
import { getWallPosts, addWallPost } from '../services/wallService';

const WallContext = createContext();

const WallProvider = ({ children, eventId }) => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            const wallPosts = await getWallPosts(eventId);
            setPosts(wallPosts);
        };
        fetchPosts();
    }, [eventId]);

    // WallProvider
    const createPost = async (content, user) => {
        const postId = await addWallPost(eventId, { content, user });
        setPosts(prevPosts => [{ content, user, id: postId }, ...prevPosts]);
    };


    return (
        <WallContext.Provider value={{ posts, createPost }}>
            {children}
        </WallContext.Provider>
    );
};

export { WallContext, WallProvider };
