import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";


import StripeSampleFooter from "./StripeSampleFooter";
import Product from "./Product";
import PriceChangeForm from "./PriceChangeForm";
import APIConfig from "../../APIConfig.js";
import BillingButton from "./BillingButton";

function Account() {

  return (
    <BillingButton></BillingButton>
  );
}

export default Account;
