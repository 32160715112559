import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import LogoView from '../components/LogoView';
import APIConfig from '../APIConfig';
import { useHistory, useLocation } from 'react-router-dom'; // Import useHistory and useLocation

const emailProviderDict = {
  provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
  requireDisplayName: true,
};

const phoneProviderDict = {
  provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  requireDisplayName: true,
};

const getAuthProvider = () => {
  if (APIConfig.studio() === true) {
    return [phoneProviderDict];
  } else {
    return [phoneProviderDict];
  }
};

const FBSigninView = () => {
  const history = useHistory();
  const location = useLocation(); // get the current location
  // const { from } = location.state || { from: { pathname: '/' } }; // get the redirect path
  const from = location.state?.from?.pathname || '/';

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: getAuthProvider(),
    callbacks: {
      signInSuccessWithAuthResult: () => {
        history.replace(from); // Redirect to the path from where the user came to the sign-in page
        return false; // Prevents the default redirect to signInSuccessUrl
      },
    },
  };

  return (
    <div>
      <div className="py-5">
        <LogoView />
      </div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  );
};

export default FBSigninView;
