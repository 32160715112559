import React from "react";
import styled from "styled-components";
import Uploader from "../../file_mgmt/uploader/Uploader";
const Container = styled.div`
  text-align: center;
  /* display: flex; */
  .container {
    display: flex; /* or inline-flex */
    flex-basis: auto; /* default auto */
  }
  ul {
    display: flex;
    justify-content: space-between;

    /* align-items: stretch; */
    /* Default */
    /* justify-content: space-between; */
    /* width: 100%; */
    /* background: #cacaca; */
    border: 2px solid blue;

    margin: 0;
    padding: 0;
  }
  li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    /* background: #fafafa; */
    padding: 20;
  }
  .item {
    order: 5; /* default is 0 */
  }
  .title {
    padding: 5px;
    margin-left: auto;
  }
  .menu-items {
    margin-right: auto;
    display: flex;
    flex-direction: row;
  }
  .input {
    border: 1px solid #000;
    width: 200px;
    padding: 20px;
    font-size: 20px;
  }
`;
function SetNameStep(props) {
  return (
    <div className="p-6">
      <p>
        subscription title{" "}
        <ul>
          <input
            name="name"
            value={props.getState("name", "")}
            onChange={props.handleChange}
          />
          .mxt
        </ul>
      </p>

      {/* <p>
        surname
        <input
          name="surname"
          value={props.getState("surname", "")}
          onChange={props.handleChange}
        />
      </p> */}
      {/* <div className="flex flex-wrap -mx-3 mb-3"> */}
      <button
        className=" p6 bg-pasha hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded-md"
        onClick={props.prev}
      >
        Previous
      </button>
      <button
        className=" p6 bg-pasha hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded-md"
        onClick={props.next}
      >
        Next
      </button>
      {/* </div> */}
    </div>
  );
}

export default SetNameStep;
