// EmailEntryView.jsx
// WelcomeView.jsx

import React from "react";
// import { Link } from "react-router-dom";
// import LogoViewSplash from "./LogoViewSplash";
// import { Input } from "reactstrap";
import APIConfig from "../../APIConfig";
const st = {
  paddingTop: "100px",
};
const url = APIConfig.testflight();

const BetaDownloadButtonView = () => {
  function handleClick() {
    window.open(url, "_blank");
  }

  return (
    <div className="px-10 flex flex-row w-full md:w-auto">
      <button
        className="w-full md:w-auto bg-white hover:bg-black text-black hover:text-white font-theFutureMonoRegular py-2 px-4 focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
        type="button"
        onClick={handleClick}
      >
        <div className="flex flex-row justify-center items-center">
          <div className="px-5">
            <img
              className="w-5 "
              style={{ filter: "invert(1)" }} // This line sets the color of the image to black
              src="https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg"
              alt="apple logo"
            />
          </div>
          <div className="text-sm font-theFutureMonoRegular">download the app</div>
        </div>
      </button>
    </div>
  );
};

export default BetaDownloadButtonView;

