// MenuButton.jsx
// components/navigation/MenuButton.jsx
import React from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";

const MenuButton = ({ toggleMenu }) => {
  return (
    <button
      onClick={toggleMenu}
      className="md:hidden flex items-center px-3 py-2 light:text-blue-500"
    >
      <Bars3Icon  className="h-6 w-6 text-blue-300" />
    </button>
  );
};

export default MenuButton;
