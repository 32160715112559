// EventDescriptionView.jsx
import React, { useState } from 'react';

const MAX_LENGTH = 600; // Maximum number of characters to show before the "Show More"

const EventDescriptionView = ({ description }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  
  // Determine if the description exceeds the max length
  const isTruncated = description.length > MAX_LENGTH;
  
  // Function to toggle the show full description state
  const toggleShowFullDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // Truncate the description if needed
  const displayedDescription = showFullDescription ? description : description.slice(0, MAX_LENGTH).trim();

  return (
    <div className="p-4 my-4 bg-gh_charcoal_secondary-200  border-gray-100 shadow-sm">
      {displayedDescription.split('\n').map((paragraph, index) => (
        <p key={index} className="mb-4 last:mb-0 font-theFutureLight text-base leading-relaxed">
          {paragraph}
        </p>
      ))}
      {isTruncated && (
        <button
          onClick={toggleShowFullDescription}
          className="text-blue-400 hover:text-blue-700 transition-colors duration-300"
        >
          {showFullDescription ? 'show less' : 'show more...'}
        </button>
      )}
    </div>
  );
};

export default EventDescriptionView;
