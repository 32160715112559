// FBSigninView.jsx

import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import UsernameForm from "./onboarding/UsernameForm";
import auth from "../services/auth";
import FBUISignin from "./FBUISignin";


const changeUsernameForm = () => {
  return (
    <div>
      <UsernameForm user={firebase.auth().currentUser} onUsernameSubmitted={handleUsernameSubmitted} />
    </div>
  );
};

const handleUsernameSubmitted = (username) => {
  console.log("username submitted, username: ", username);
};

function UsernameEditView() {
  
  const [username, setUsername] = useState("");
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user);
        if (!user) {
          console.log("no user");
        } else {
          const idTokenPromise = user.getIdToken(/* forceRefresh */ true);
          auth.me(idTokenPromise).then((response) => {
            if (response) {
              setUsername(response.username);
            } else {
              console.log("no response");
            }
          });
          idTokenPromise.then((idToken) => {
            localStorage.setItem("token", idToken);
          });
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (!isSignedIn) {
    return (
      <div>
       <FBUISignin/>
      </div>
    );
  }
  return (
    <div className="dark:text-white py-2">
      <div>{changeUsernameForm()}</div>
    </div>
  );
}

  export default UsernameEditView;