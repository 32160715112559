import firebaseconfig from "./firebaseIndex";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import React, { useState, useEffect } from "react";
import APIConfig from "../APIConfig";
import axios from "axios";
var token = localStorage.getItem("token");

// axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
// axios.defaults.headers.post['Content-Type'] = 'application/json';
export const fb = {
  // const [token,setToken] = useState(localStorage.getItem("token"));

  // firebase helper methods go here...
  ping: () => {
    //("ping");
    const ep = APIConfig.test();
    // const ep = APIConfig.profilesURI() + "check_username/";
    // const headers = APIConfig.headers()
    //("token: ", token);
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    //(axios.defaults.headers);
    //(ep);
    const params = {
      username: "samir",
    };
    axios.get(ep, { params }).then((res) => {
      //(res);
    });
  },
  // firebase helper methods go here...
  // connect: (token, email, website) => {
  connect: (token) => {
    //("connect");
    // token = localStorage.getItem("token");

    const ep = APIConfig.connect();

    // const ep = APIConfig.profilesURI() + "check_username/";
    // const headers = APIConfig.headers()
    //("token: ", token);
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    //("ehaders: ", axios.defaults.headers);
    axios.defaults.headers.post["Content-Type"] = "application/json";
    //(axios.defaults.headers);
    //(ep);
    // axios.post(ep, { params }).then((res) => {
    //   //(res.data);
    //   // stripe_user
    //   window.location.href = res.data;
    // });
    axios.get(ep).then((res) => {
      //(res.data);
      window.location.href = res.data;
    });
  },
  getUserData: () => {
    // var user = firebaseconfig.auth().currentUser;
    var name, email, photoUrl, uid, emailVerified;
    var user = firebase.auth().currentUser;

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        //("signed in");
        name = user.displayName;
        email = user.email;
        photoUrl = user.photoURL;
        emailVerified = user.emailVerified;
        uid = user.uid; // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.
        // User is signed in.
        return name;
        //(name, email, photoUrl, emailVerified, uid);
      } else {
        // No user is signed in.
        //("signed in");
        return "___";
      }
    });
  },
};
