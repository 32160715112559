import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import UsernameForm from "../../auth/onboarding/UsernameForm";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import AuthProvider from "../../provider/AuthProvider";
import NextButton from "./NextButton";

const auth = getAuth(firebase.app());

const CreateUsernameFromEventView = (props) => {
  const [user, loading, error] = useAuthState(auth);
  const [isUsernameCreated, setIsUsernameCreated] = useState(false);
  const [createdUsername, setCreatedUsername] = useState(''); // To store the created username
  let history = useHistory();
  let location = useLocation();


  useEffect(() => {
    if (location.state) {
      console.log("location.state", location.state);
    }
  }, [location]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (location.state.token) {
          localStorage.setItem("token", location.state.token);
        } else {
          user.getIdToken(true).then((idToken) => {
            localStorage.setItem("token", idToken);
          });
        }
      } else {
        AuthProvider.refreshToken();
      }
    });
  }, [user, loading, error]);

  const navigateToNameEntryView = () => {
    history.push("/name_entry/", { eventData: location.state.eventData });
  };

  const handleUsernameSubmit = (username) => {
    setIsUsernameCreated(true);
    // Store the created username and navigate to the next step
    if (username) {
      navigateToNameEntryView();
    }
  };

  // Removed the NextButton component since we are now navigating automatically

  return (
    <div className="font-theFutureMonoRegular bg-gray-900 min-h-screen flex flex-col justify-center text-white">
      <div className="w-full max-w-2xl mx-auto bg-gray-800 shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <h1 className="text-xl text-center font-semibold">Create a Username</h1>
          {loading && <div className="text-center">Loading...</div>}
          {error && <div className="text-red-400 text-center">{error.message}</div>}
        </div>
        {user && (
          <UsernameForm
            user={user}
            onUsernameSubmitted={handleUsernameSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default CreateUsernameFromEventView;