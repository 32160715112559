import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

const UsernameEntryTextField = (props) => {
  const [user, setUser] = useState({});
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  const [startedTyping, setStartedTyping] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const [displayErrorCaption, setDisplayErrorCaption] = useState(false);

  const debounceTimeout = useRef(null);

  const nameRegex = /^[a-zA-Z0-9_.]{3,30}$/;

  const validateUsername = () => {
    if (nameRegex.test(username)) {
      props.setInputValidity(true);
      setInputValid(true);
      return true;
    } else {
      props.setInputValidity(false);
      setInputValid(false);
      return false;
    }
  };
  const handleUsernameChange = (event) => {
    const newValue = event.target.value.trim(); // trim any whitespace

    setUsername(newValue);
    setStartedTyping(true);

    // Clear the error when user starts typing
    if (newValue === "") {
      setDisplayErrorCaption(false);
    }

    // Clear any existing timeouts
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    const isValid = nameRegex.test(newValue); // Check validity here

    // Set a new timeout
    debounceTimeout.current = setTimeout(() => {
      if (newValue !== "") { // Don't check for empty values
        setInputValid(isValid);
        props.setInputValidity(isValid);
        setDisplayErrorCaption(!isValid && newValue.length > 0); // Show error caption if not valid and not empty
      }

      // Only send a request if username is valid and not empty
      if (isValid && newValue.length > 0) {
        props.onUsernameCheck(newValue); // This checks the username
        props.onUsernameChange(newValue); // This lifts up the state
      }
    }, 500);
  };






  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    }
  }, []);

  useEffect(() => {
    if (props.user) {
      setUser({
        email: props.user.email,
        uid: props.user.uid,
      });
      setUserLoggedIn(true);
    } else {
      setUser({});
      setUserLoggedIn(false);
    }
  }, [props.user]); // Dependency array corrected to [props.user]  

  return (
    <div className="grid grid-rows-2 gap-3">
      <div className="col-span-6 row-span-1">
        <Form.Control
          type="text"
          placeholder="enter username"
          className="col-span-2 bg-inherit font-theFutureMonoRegular dark:bg-transparent p-1 px-2 border-blue-500 border-2"
          onChange={handleUsernameChange}
        />
      </div>
      {!props.loading && props.available && inputValid ? (
        <button
          className="bg-green-600 font-theFutureMonoRegular h-8 w-40 hover:bg-green-900"
          onClick={props.onUsernameSubmit}
        >
          continue
        </button>
      ) : null}
      {displayErrorCaption && (
        <div>
          <div className="text-red-500">username is invalid</div>
          <div className="col-span-6 row-span-1 py-2 font-theFutureLight">
            <div>
              please enter a username that is between 3-30 characters long and
              contains only letters (english alphabet), numbers, periods, and underscores.
              Spaces are not allowed.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsernameEntryTextField;
