// PostList.jsx
import React, { useContext } from "react";
import { WallContext } from "../contexts/WallContext.jsx";
import Post from "./Post";

const PostList = () => {
  const { posts } = useContext(WallContext);

  return (
    <div className="py-2">
      {posts.map((post, index) => (
        <Post key={index} content={post.content} user={post.user} />
      ))}
    </div>
  );

};

export default PostList;
