// UsernameForm.jsx

// parent view to UsernameEntryTextField.jsx

// UsernameForm.jsx
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { onAuthStateChanged } from "firebase/auth";
import firebase from "firebase/compat/app";
import UsernameEntryTextField from "./UsernameEntryTextField";
import auth from "../../services/auth";
import { useAuthState } from "react-firebase-hooks/auth";

const UsernameForm = (props) => {
  const fbAuth = firebase.auth();
  const [firstLoad, setFirstLoad] = useState(true);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const [user, loadingAuth, errorAuth] = useAuthState(fbAuth);

  const handleUsernameChange = (username) => {
    setUsername(username);
  };

  // put a loading indicator here to show that the username is being submitted
  // if the response is that the username is taken, then show a message demonstrating that
  // if the response is that the username is not taken, then show a message demonstrating that

  const showLoadingIndicator = () => {
    return <div className="font-theFutureMonoRegular text-yellow-200 py-5"> loading...</div>;
  };

  const showUsernameTakenMessage = () => {
    return <div className="font-theFutureMonoRegular text-red-500 py-5">username is taken</div>;
  };

  const showUsernameNotTakenMessage = () => {
    return (
      <div className="font-theFutureMonoRegular text-green-500 py-5">username is available</div>
    );
  };
  // update props to include the available state

  // Within UsernameForm.jsx

const updateUsername = (token) => {
  setLoading(true);
  auth.updateUsername(username, token).then((response) => {
    setLoading(false);
    if (response && response.successful) {  // Make sure to check the actual response structure for success confirmation
      console.log("username submitted, response: ", response);
      props.onUsernameSubmitted(username); // Call the callback function on successful username creation
    } else {
      console.log("username not submitted or username is taken");
      setAvailable(false); // Indicate that the username is not available
    }
  }).catch((error) => {
    console.error("Error during username update:", error);
    // Handle any errors that occur during update
  });
};


  const handleUsernameCheck = (usernameToCheck) => {
    setLoading(true);

    auth.checkUsername(usernameToCheck)
      .then((response) => {
        if (response) {
          const invalid = response["invalid"];
          setFirstLoad(false);
          if (invalid) {
            setInputValid(false);
            setInputValidity(false);
            setFirstLoad(true);
          } else {
            const status = response["exists"];

            if (status) {
              setAvailable(false);
            } else {
              setAvailable(true);
            }
          }
        } else {
          console.log("no response");
        }
      })
      .catch(error => {
        console.error("Error during username check:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUsernameSubmit = () => {
    if (user) {
      user.getIdToken(true)
        .then(function (idToken) {
          updateUsername(idToken);
        });
    } else {
      console.log("no user");
      // alert("please log in");
    }
  };

  const setInputValidity = (valid) => {
    // set the input to invalid
    setInputValid(valid);
  };
  const logicalRender = () => {
    if (loading) {
      return showLoadingIndicator();
    }

    if (!firstLoad && inputValid) {
      if (available) {
        return showUsernameNotTakenMessage();
      } else {
        return showUsernameTakenMessage();
      }
    }

    return <div></div>;
  };


  return (
    <div>
      {/* if loading then deactivate the textfield */}
      <UsernameEntryTextField
        user={props.user}
        onUsernameChange={handleUsernameChange}
        onUsernameSubmit={handleUsernameSubmit}
        onUsernameCheck={handleUsernameCheck}
        setInputValidity={setInputValidity}
        loading={loading}
        disabled={false}
        available={available}
      />

      {logicalRender()}
    </div>
  );
};

export default UsernameForm;
