import React from "react";
import SpeechBubble from "./SpeechBubble"; // import the SpeechBubble component
import AvatarView from "../../events/AvatarView";

const Post = ({ content, user }) => {
  if (!content || !user) {
    return <div>Loading...</div>;
  }

  let initial =
    user.username && user.username.length > 0
      ? user.username.charAt(0).toUpperCase()
      : "?";
  let usernameDisplay =
    user.username && user.username.length > 0 ? user.username : "unknown";

  return (
    <div className="post flex items-center space-x-4 my-3">
      <AvatarView username={usernameDisplay} profilePic={user.profilePicture} />
      <div>
        <h2 className="post-user text-sm mb-2 text-blue-400">
          <span className="font-mono">@</span>
          <span className="font-theFutureMonoRegular">{usernameDisplay}</span>
        </h2>
        <SpeechBubble>{content}</SpeechBubble>
      </div>
    </div>
  );
};

export default Post;
