import React, { useState } from 'react';
import Spinner from '../../loading/Spinner';

const ArtistComponent = ({ artist, isEditMode, onEditToggle, onNameChange, onUpdate, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async (artistId) => {
    setIsLoading(true);
    await onUpdate(artistId);
    setIsLoading(false);
  };

  const handleDeleteConfirm = (artistId) => {
    // Confirmation dialog
    const isConfirmed = window.confirm(`Are you sure you want to delete ${artist.name}?`);
    if (isConfirmed) {
      handleDelete(artistId);
    }
  };

  const handleDelete = async (artistId) => {
    setIsLoading(true);
    await onDelete(artistId);
    setIsLoading(false);
  };

  return (
    <li className="text-sm flex items-center justify-between py-2">
      {isEditMode ? (
        <input
          type="text"
          value={artist.name}
          className="flex-grow mr-2 p-2 border rounded focus:outline-none focus:border-blue-300"
          onChange={(e) => onNameChange(artist.id, e.target.value)}
          disabled={isLoading}
        />
      ) : (
        <span className={`flex-grow ${isLoading ? 'text-gray-400' : ''}`}>{artist.name}</span>
      )}

      <div className="flex items-center">
        {isLoading && <Spinner />}
        <button
          onClick={() => onEditToggle(artist.id)}
          className="mx-1 text-white bg-blue-500 hover:bg-blue-600 p-1 rounded focus:outline-none disabled:bg-gray-400"
          disabled={isLoading}
        >
          {isEditMode ? 'Cancel' : 'Edit'}
        </button>
        {isEditMode && (
          <>
            <button
              onClick={() => handleUpdate(artist.id)}
              className="mx-1 text-white bg-green-500 hover:bg-green-600 p-1 rounded focus:outline-none disabled:bg-gray-400"
              disabled={isLoading}
            >
              Save
            </button>
            <button
              onClick={() => handleDeleteConfirm(artist.id)}
              className="mx-1 text-white bg-red-500 hover:bg-red-600 p-1 rounded focus:outline-none disabled:bg-gray-400"
              disabled={isLoading}
            >
              Delete
            </button>
          </>
        )}
      </div>
    </li>
  );
};

export default ArtistComponent;
