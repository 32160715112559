import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BsGearWideConnected } from "react-icons/bs";
import { firebaseAuth } from "../provider/AuthProvider";
import Uploader from "./file_mgmt/uploader/Uploader";

import DarkModeToggle from "./aesthetic/DarkModeToggle";
import SettingsButton from "./buttons/SettingsButton";
import ArtistRegistrationButton from "./buttons/ArtistRegistrationButton";
import UploadButton from "./buttons/UploadButton";
import MenuTabs from "./artist_dashboard/MenuTabs";
import GridContainer from "./themes/GridContainer";
import SearchBar from "./search/SearchBar";

const Home = (props) => {
  const [theme, setTheme] = useState("light");

  // The function that toggles between themes
  const toggleTheme = () => {
    // if the theme is not light, then set it to dark
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };
  const { handleSignout } = useContext(firebaseAuth);

  return (
    <div>
      <SearchBar />
    </div>
  );
};

export default Home;
