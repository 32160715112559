import { useState, useCallback, useRef } from "react";
import drafting from "../../../../../services/drafting";

const useArtistChange = (track, album) => {
  // Use ref to store the current artist ID
  const selectedArtistRef = useRef(track.metadata.artist_id);
  
  // State for triggering re-renders if necessary
  const [, forceUpdate] = useState();

  const handleArtistChange = useCallback(async (artist) => {
    if (artist.id !== selectedArtistRef.current) {
      selectedArtistRef.current = artist.id;

      const updatedTrack = {
        ...track,
        metadata: {
          ...track.metadata,
          artist_id: artist.id,
        },
      };

      try {
        await drafting.manageTrack(album, updatedTrack, "update");
        console.log("Artist updated successfully:", artist.id);
        forceUpdate({}); // Trigger re-render if you need to reflect the change in the component
      } catch (error) {
        console.error("Error updating artist:", error);
      }
    }
  }, [track, album]);

  // Expose only the current value of the ref if needed
  return { selectedArtist: selectedArtistRef.current, handleArtistChange };
};

export default useArtistChange;
