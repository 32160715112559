// FBSigninView.jsx

import React from "react";
import firebase from "firebase/compat/app";
import { useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

// import oauthprovider from "firebase/auth";
// import { OAuthProvider } from "firebase/auth";

// const provider = new OAuthProvider('apple.com');
// auth_apple_provider_create.js
import { useHistory } from "react-router-dom";
import APIConfig from "../APIConfig";
const FBUISignin = (props) => {
  //   const [user, setUser] = useState({});
  const [signedIn, setSignedIn] = useState(false); // Local signed-in state.
  let history = useHistory();
  const emailProviderDict = {requireDisplayName: true, provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,}
  const phoneProviderDict = {requireDisplayName: true, provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,}
  const getAuthProvider = () => {
    if (APIConfig.studio() === true) {
      return [ emailProviderDict, phoneProviderDict ]
    } else {
      return [ phoneProviderDict ]
    }
  }
  const uiConfig = {
    signInFlow: "popup",
    signInOptions: getAuthProvider(),
    callbacks: {

      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        console.log("signInSuccessWithAuthResult", authResult, redirectUrl);
        history.push({ 
          pathname: '/create_username_from_event',
          state: {eventData: props.event, token: authResult.user.getIdToken()}
         });
        // setSignedIn(true);
        return false;
      },
    },
  };

  // if (!signedIn) {
    return (
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    );
  // } else {
  //   return (
  //     <div>
  //       <h1>My App</h1>
  //       <p>
  //         Welcome {firebase.auth().currentUser.displayName}! You are now
  //         signed-in!
  //       </p>
  //       <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
  //     </div>
  //   );
  // }
};

export default FBUISignin;
//   export default SignInScreen;
