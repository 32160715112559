// AvatarView.jsx
import React, { useState } from 'react';

const AvatarView = ({ username, profilePic, showUsername, showUsernameOnHover }) => {
  const [isHovered, setIsHovered] = useState(false);
  const avatarUrl = profilePic || `https://ui-avatars.com/api/?name=${username}&background=4B5563&color=fff`;

  // Determine if the username should be shown based on the props
  const shouldDisplayUsername = showUsername || (isHovered && showUsernameOnHover);

  return (
    <div 
      className="flex flex-col items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-10 h-10 rounded-full overflow-hidden border-2 border-gray-700">
        <img
          src={avatarUrl}
          alt={`Avatar of ${username}`}
          className="object-cover w-full h-full"
        />
      </div>
      {/* <p className={`text-sm mt-2 transition-opacity duration-500 ease-in-out ${shouldDisplayUsername ? 'opacity-100' : 'opacity-0'}`}>
        {username}
      </p> */}
    </div>
  );
};

// Set default props in case they are not provided
AvatarView.defaultProps = {
  showUsername: false, // By default, we show the username
  showUsernameOnHover: false, // By default, do not show the username only on hover
};

export default AvatarView;
