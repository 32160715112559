import React from 'react';

const CreateEventView = () => {
  return (
    <div className="relativeflex flex-col justify-center min-h-screen overflow-hidden ">
      <div className="w-full   p-6 m-auto bg-white shadow-xl shadow-rose-600/40 ring-2 ring-indigo-600 lg:max-w-xl">
        <h1 className="text-3xl font-theFutureMonoRegular font-semibold text-center text-indigo-700 underline uppercase decoration-wavy">
          Create An Event
        </h1>
        <form className="mt-6">
          <div className="mb-2">
            <label>
              <span className="font-theFutureMonoRegular text-gray-700">event title</span>
              <input font-theFutureMonoRegular
                type="text"
                name=""
                className="
            font-theFutureMonoRegular
            w-full
            block px-16 py-2 mt-2
            border-gray-300
            shadow-sm
            focus:border-indigo-300
            focus:ring
            focus:ring-indigo-200
            focus:ring-opacity-75
            border-2
          "
                placeholder="basement show..."
              />
            </label>
          </div>
          <div className="mb-2">
            <label>
              <span className="font-theFutureMonoRegular text-gray-700">email address</span>
              <input
                name="email"
                type="email"
                className="
            block
            w-full
            mt-2 px-16 py-2
            border-2
            border-gray-300
            shadow-sm
            focus:border-indigo-300
            focus:ring
            focus:ring-indigo-200
            focus:ring-opacity-50
          "
                placeholder="john.cooks@example.com"
                required
              />
            </label>
          </div>
          <div className="mb-2">
            <label>
              <span class=" font-theFutureMonoRegular text-gray-700">description</span>
              <textarea
                name="description"
                className="
            block
            w-full
            mt-2 px-16 py-8
            border-gray-300
            shadow-sm
            focus:border-indigo-300
            focus:ring
            focus:ring-indigo-200
            focus:ring-opacity-50
            border-2
          "
                rows="5"
              ></textarea>
            </label>
          </div>

          <div class="mb-6">
            <button
              type="submit"
              className="
            h-10
            px-5
            font-theFutureMonoRegular
            text-indigo-100
            bg-indigo-700
            transition-colors
            duration-150
            focus:shadow-outline
            hover:bg-indigo-800
          "
            >
              save
            </button>
          </div>
          <div></div>
        </form>
      </div>
    </div>
  );
};

export default CreateEventView;