// ButtonTest.jsx
import React from "react";
import ReactDOM from "react-dom";

import NiceButton from "./NiceButton";

import "./styles.css";

function ButtonTest() {
  const [isFirstButtonLoading, setIsFirstButtonLoading] = React.useState(false);
  const [isSecondButtonLoading, setIsSecondButtonLoading] = React.useState(
    false
  );
  const [loadingSpeed, setLoadingSpeed] = React.useState(1);

  React.useEffect(() => {
    if (isFirstButtonLoading) {
      setTimeout(() => {
        setIsFirstButtonLoading(false);
      }, 1000 / loadingSpeed);
    }

    if (isSecondButtonLoading) {
      setTimeout(() => {
        setIsSecondButtonLoading(false);
      }, 1000 / loadingSpeed);
    }
  }, [isFirstButtonLoading, isSecondButtonLoading, loadingSpeed]);

  return (
    <div className="App">
      Related blog post:{" "}
      <a href="https://humble.dev/creating-a-nice-loading-button-with-react-hooks/">
        https://humble.dev/creating-a-nice-loading-button-with-react-hooks/
      </a>
      <div>
      </div>
      <div>
        <NiceButton
          isLoading={isSecondButtonLoading}
          onClick={() => setIsSecondButtonLoading(true)}
        >
          Click me to start a nicer loading button experience!
        </NiceButton>
      </div>
      Try increasing the loading speed
      <input
        type="range"
        max={10}
        min={1}
        value={loadingSpeed}
        onChange={e => setLoadingSpeed(e.target.value)}
      />
      Loading speed:{loadingSpeed}
    </div>
  );
}

export default ButtonTest;
