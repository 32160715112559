// EventLinkButton.jsx
import React from 'react';

import {
    MapPinIcon,
    CalendarIcon,
    InformationCircleIcon,
    CheckIcon,
    ClockIcon,
    LinkIcon,
  } from "@heroicons/react/24/solid";
import APIConfig from '../../APIConfig';

const EventLinkButton = ({ event, linkCopied, presentEventCopySuccessToast }) => {
  if (!linkCopied) {
    return (
      <button
        className=" hover:text-white   font-theFutureMonoRegular"
        onClick={() => {
          navigator.clipboard.writeText(
            APIConfig.siteBaseURL() + "/e/" + event.id
          );
          presentEventCopySuccessToast();
        }}
      >
        <div className="border-1  hover:bg-blue-400 ">
          <div className="px-1 flex flex-row p-1">
            <div className="p-1 hover:text-white">
              <LinkIcon className="h-4 w-4   dark:" />
            </div>
            <div className=" px-1">share</div>
          </div>
        </div>
      </button>
    );
  } else {
    return (
      <button
        className=" hover:text-white   font-theFutureMonoRegular"
        onClick={() => {
          navigator.clipboard.writeText(
            APIConfig.siteBaseURL() + "/e/" + event.id
          );
          presentEventCopySuccessToast();
        }}
      >
        <div className="bg-blue-300 hover:bg-blue-400 ">
          <div className="px-1 flex flex-row p-1">
            <div className="p-1 hover:text-white">
              {linkCopied ? (
                <div className="text-white px-1 ">
                  <CheckIcon className="h-4 w-4 text-white  " />
                </div>
              ) : (
                <LinkIcon className="h-4 w-4 text-white  dark:" />
              )}
            </div>
            <div className=" text-white px-1">link copied</div>
          </div>
        </div>
      </button>
    );
  }
};

export default EventLinkButton;