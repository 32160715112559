import React, { useState } from "react";
import APIConfig from "../../APIConfig";

const WideButton = (props) => {
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState(props.text);
  const isAirtrak = APIConfig.airtrak();
  const isMixtape = APIConfig.studio();

  const handleClick = () => {
    setLoading(true);
    props.lambda();
  };

  const handleLoading = () => {
    if (props.loading) {
      setButtonText("loading...");
    } else {
      setButtonText(props.text);
    }
  };

  // Define base classes for centering text and other common styles
  const baseButtonClasses = "flex items-center justify-center rounded-md font-theFutureMonoRegular border-2 col-span-1";

  // Extend the base classes with specific styles for Airtrak
  const airtrakButtonClasses = `${baseButtonClasses} hover:text-gh_blue-100 dark:border-gh_charcoal_foreground_primary-100 hover:border-gh_blue-100 mb-2 px-4 py-2 border-1`;

  // Extend the base classes with specific styles for Mixtape
  const mixtapeButtonClasses = `${baseButtonClasses} py-0.5 w-40 h-7 text-green-400 bg-black-700 hover:bg-green-400 dark:text-green-300 dark:border-green-400 border-green-400 hover:text-black`;

  // Extend the base classes with default styles
  const defaultButtonClasses = `${baseButtonClasses} py-0.5 w-40 h-7 text-slate-100 bg-slate-700 hover:bg-violet-400 hover:text-slate-300 hover:border-slate-300 dark:text-violet-300 dark:border-violet-400`;

  if (isAirtrak) {
    return (
      <button
        loading={handleLoading}
        onClick={props.lambda}
        className={airtrakButtonClasses}
      >
        {props.text}
      </button>
    );
  }

  if (isMixtape) {
    return (
      <button
        loading={handleLoading}
        onClick={props.lambda}
        className={mixtapeButtonClasses}
      >
        {props.text}
      </button>
    );
  }

  // Use default styles if none of the above conditions are met
  return (
    <button
      loading={handleLoading}
      onClick={props.lambda}
      className={defaultButtonClasses}
    >
      {props.text}
    </button>
  );
};

export default WideButton;
