// LabelRegistrationView.jsx
import React, { useState, useEffect,  } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import api from "../../api";
import { fb } from "../../firebase/fb";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import storage from "../../services/storage";
import studio_api from "../../studio_api";
import StandardButton from "../buttons/StandardButton";
import Container from "../themes/Container";
import GridContainer from "../themes/GridContainer";
import label_api from "../../services/label_api";
var fs_uid = "";

// function submit(name) {
// api.submitArtistName("sammydelila");
//   api.me();
//   // api.dothing();
//   // alert("Hi your artist name is: " + formData.artistName);
// }

const connectStripe = () => {
  fb.connect();
};

export default function LabelArtistsView({ label, artists }) {
  let history = useHistory();

  const { register, handleSubmit } = useForm();
  const [token, setToken] = useState("");
  const [selectedAddArtist, toggleAddArtist] = useState(false);
  const [uid, setUID] = useState("");
  
  // const [artists, setArtists] = useState([]);
  // Similar to componentDidMount and componentDidUpdate:
  // setLabel() {

  // }

  async function onSubmitForm(formData) {
    // //(formData);

    label_api.addArtistToLabel(formData["artistName"]).then((res) => {
     
      alert("artist added");
    });
  }
  useEffect(() => {
    // Update the document title using the browser API
    // studio_api.login();
    // api.me();

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fs_uid = user.uid;
        setUID(user.uid);
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (token) {
            setToken(token);
          });
      } else {
      }
    });
  }, []);

  function deleteArtistButton(artist) {
    return (
      <StandardButton
        mxt_red
        onClick={() => {
         let r =  label_api.deleteArtist(artist.id)
        //  r.then((t) => {
        //    window.location.reload()
        //  })
          
        }}
      >
        x
      </StandardButton>
    );
  }
  function newArtistForm() {
    if (selectedAddArtist) {
      return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <label>
            <input
              className="text-box bordered-light padded-5 dark"
              placeholder="add artist"
              type="text"
              name="artistName"
              ref={register}
            />
          </label>
          <StandardButton mxt_primary onSubmit={handleSubmit}>
            {" "}
            submit
            {/* <input style={{ backgroundColor: "white" }} type="submit" value="submit" /> */}
          </StandardButton>
          <StandardButton
            onClick={() => {
              toggleAddArtist(!selectedAddArtist);
            }}
            mxt_red
          >
            {" "}
            cancel{" "}
          </StandardButton>
        </form>
      );
    } else {
      return (
        <StandardButton
          onClick={() => {
            toggleAddArtist(!selectedAddArtist);
          }}
          mxt_primary
        >
          {" "}
          + add artist
        </StandardButton>
      );
    }
  }
  function getArtistsList() {
    const listItems = artists.map((artist) => (
      <div>

          <StandardButton
            onClick={() => {
              history.push({
                pathname: "/view/artist",
                artist_id: artist.id,
                artist_name: artist.name,
              });
            }}
          >
            {artist.name}
          </StandardButton>

        {deleteArtistButton(artist)}
      </div>
    ));
    return <div>{listItems}</div>;
  }
  return (
    <GridContainer className="center padded ">
      <div className="center padded ">
        <div className="center padded ">{label}</div>

        {newArtistForm()}
        {getArtistsList()}
        {/* <StandardButton mxt_primary onClick={() => connectStripe()}>
        connect stripe
      </StandardButton> */}
      </div>
    </GridContainer>
  );
}
