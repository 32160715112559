// useTrackEditor.js
// useTrackEditor.js
import { useState } from 'react';
import storage from '../../../services/storage';

const useTrackEditor = (task, album, index) => {
    const [isEditing, setIsEditing] = useState(false);
    const [trackName, setTrackName] = useState(task.trackName);

    const saveTrackName = (newTrackName) => {
        if (trackName !== newTrackName) {
            const updatedTask = { ...task, trackName: newTrackName };
            storage.updateAlbumDraftTrack(updatedTask, album, index);
            setTrackName(newTrackName);
        }
        setIsEditing(false);
    };

    const startEditing = () => setIsEditing(true);
    const cancelEditing = () => setIsEditing(false);

    return { isEditing, startEditing, cancelEditing, saveTrackName, trackName };
};

export default useTrackEditor;